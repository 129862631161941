
// datePicker module

// to use define a div with an id ending in _wrap, having the css class datepicker-wrap eg.:
// the id can be used to define the with of the visible element
// <div id="article_from_wrap" class="datepicker-wrap"></div>

// init using: datePicker.add("article_from_wrap", "20.05.2021", "10:00", ENABLE_HOURS ? "noTime-remove" : "noTime", false, false);
// the day need to be in dd.mm.YYYY format
// the params are: element id, day, time, show time or not, year picker, is disabled
// setting the year picker on true allows the year to be direcly selected

// the date picker modules will add the html to the div

// the datepicker can work in pair - in a from to scenario, for that the two parents of the pickers need to be siblings and have the class datepicker_root
// the from picker's parent needs to have the class start_date_picker

datePicker = (function () {

    DPcurrentTime = new Date();
    DPmonth = DPcurrentTime.getMonth() + 1;
    DPday = DPcurrentTime.getDate();
    DPyear = DPcurrentTime.getFullYear();

    //var defaultDate = year + "/" + month + "/" + day;
    DPdefaultDate = DPday + "." + DPmonth + "." + DPyear;

    DPtimerange = "00:00, 00:15, 00:30, 00:45, ";
    DPtimerange += "01:00, 01:15, 01:30, 01:45, ";
    DPtimerange += "02:00, 02:15, 02:30, 02:45, ";
    DPtimerange += "03:00, 03:15, 03:30, 03:45, ";
    DPtimerange += "04:00, 04:15, 04:30, 04:45, ";
    DPtimerange += "05:00, 05:15, 05:30, 05:45, ";
    DPtimerange += "06:00, 06:15, 06:30, 06:45, ";
    DPtimerange += "07:00, 07:15, 07:30, 07:45, ";
    DPtimerange += "08:00, 08:15, 08:30, 08:45, ";
    DPtimerange += "09:00, 09:15, 09:30, 09:45, ";
    DPtimerange += "10:00, 10:15, 10:30, 10:45, ";
    DPtimerange += "11:00, 11:15, 11:30, 11:45, ";
    DPtimerange += "12:00, 12:15, 12:30, 12:45, ";
    DPtimerange += "13:00, 13:15, 13:30, 13:45, ";
    DPtimerange += "14:00, 14:15, 14:30, 14:45, ";
    DPtimerange += "15:00, 15:15, 15:30, 15:45, ";
    DPtimerange += "16:00, 16:15, 16:30, 16:45, ";
    DPtimerange += "17:00, 17:15, 17:30, 17:45, ";
    DPtimerange += "18:00, 18:15, 18:30, 18:45, ";
    DPtimerange += "19:00, 19:15, 19:30, 19:45, ";
    DPtimerange += "20:00, 20:15, 20:30, 20:45, ";
    DPtimerange += "21:00, 21:15, 21:30, 21:45, ";
    DPtimerange += "22:00, 22:15, 22:30, 22:45, ";
    DPtimerange += "23:00, 23:15, 23:30, 23:45, 23:59";

    DPdefaultTime = DPcurrentTime.getHours() + ":00";

    // adds the required html and initialises the date picker
    var addPicker = function (idDatepickerWrap, defDate, defTime, hideTime, yearPicker, is_disabled = false) {

        var id = idDatepickerWrap.replace(/_wrap+$/, '');

        /*
        * split only by last '_' because the id might contain other '_'s
        * This is used only for articles in contract
        */
        var lastIndex = id.lastIndexOf('_');
        var name = id.substr(0, lastIndex);
        var name_i = id.substr(lastIndex + 1);

        var inputClass = $('#' + idDatepickerWrap).data('pickerclass');
        if(!inputClass) {
            inputClass = '';
        }

        var defaultDate = (defDate != '' ? defDate : DPdefaultDate);
        var defaultTime = (defTime != '' ? defTime : DPdefaultTime);

        /*
        bellow is the datepicker holder, data inside of it will be passed to the above input:
          Mandatory: data-timerange: plain list with hours that are available, which will be passed into an array
          Optional: data-defaultTime: the default SELECTED time when user opens datepicker; if not set will set by default today date; WILL search in the above array from default list of hours
          Optional: data-defaultDate: the default SELECTED date when user opens datepicker - format: yyyy/mm/dd; if not set will set by default to first value 
        */
        let disabled_attr = is_disabled ? "disabled" : "";

        var datepicker_html = '<input type="text" class="datetimepicker-output ' + disabled_attr + '" name="' + name + '[' + name_i + ']" ' +
            ' readonly placeholder="Select a time and a day" value=""/>';

        datepicker_html += '<input type="hidden" class="datepicker-input ' + inputClass + '" id="' + id + '"';
        datepicker_html += '   data-timerange="' + DPtimerange + '"';
        datepicker_html += '   data-defaultTime="' + defaultTime + '"';
        datepicker_html += '   data-defaultDate="' + defaultDate + '"';
        datepicker_html += '   value="' + defaultDate + ' ' + defaultTime + '"/>';

        datepicker_html += '<button type="button" class="trigger-datepicker" ' + disabled_attr + '>';
        datepicker_html += ' <svg class="icon-calendar" width="15px" height="16px">';
        datepicker_html += '    <use xlink:href="#icon-calendar"></use>';
        datepicker_html += ' </svg>';
        datepicker_html += '</button>';

        $("#" + idDatepickerWrap).html(datepicker_html);

        datePicker.init('#' + id, hideTime, yearPicker);
    };

    var initPickers = function (id, hideTime, yearPicker) {

        let is_year_picker = (typeof yearPicker !== 'undefined' && yearPicker === true);

        $(id).each(function (el) {

            var self = $(this),
                output = $(this).siblings('.datetimepicker-output'),
                dataCollector = {},
                monthNames = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                weekDays = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],

                // initializes default params
                defaultData_init_function = (function () {
                    var iniData = {},
                        tempDate = self.data('defaultdate');

                    iniData.defaultTime = self.data('defaulttime');

                    if (tempDate) {
                        iniData.day = tempDate.replace(/\s/g, '').split('.')[0] || undefined;
                        iniData.month = tempDate.replace(/\s/g, '').split('.')[1] || undefined;
                        iniData.year = tempDate.replace(/\s/g, '').split('.')[2] || undefined;

                        iniData.defaultDate = iniData.year + '-' + iniData.month + '-' + iniData.day;
                    }

                    dataCollector.iniData = {
                        defaultDate: iniData.defaultDate,
                        defaultTime: iniData.defaultTime
                    };
                })(),

                timeRangeValues = self.data('timerange').replace(/\s/g, '').split(',');

            var pickerOptions = {
                firstDay: 1,
                monthsFull: monthNames,
                weekdaysShort: weekDays,
                closeOnSelect: hideTime == 'noTime' ? true : false,

                // when the date picker is initialized, also initialize the time slider on the bottom part of the date picker
                onStart: function () {
                    self.siblings('.picker').find('.picker__wrap').after('<div class="timerange-slider"/>');

                    var timeSlider = self.siblings('.picker').find('.timerange-slider'),
                        defaultTime = dataCollector.iniData.defaultTime || undefined,
                        defaultDate = dataCollector.iniData.defaultDate || undefined;

                    // hide timeSlider if required
                    timeSlider.addClass(hideTime == 'noTime' ? 'hidden' : '');

                    noUiSlider.create(timeSlider[0], {
                        start: [0],
                        step: 1,
                        range: {
                            'min': [0],
                            'max': [timeRangeValues.length - 1]
                        }
                    });

                    timeSlider.find('.noUi-handle').append('<div class="timeRangeValue"/>');

                    if (defaultTime && timeRangeValues.indexOf(defaultTime)) {
                        dataCollector.timeSlider = defaultTime;

                        timeSlider[0].noUiSlider.set(timeRangeValues.indexOf(defaultTime));
                        timeSlider.find('.timeRangeValue').text(dataCollector.timeSlider);

                    } else {
                        dataCollector.timeSlider = timeRangeValues[parseInt(timeSlider[0].noUiSlider.get())];
                    }

                    const now = new Date();
                    if (dataCollector.iniData.defaultDate) {
                        // console.log(dataCollector.iniData.defaultDate);

                        const dateForInit = new Date(dataCollector.iniData.defaultDate);

                        this.set('select', dateForInit);

                        if(!is_year_picker) {
                            this.set('min', now < dateForInit ? now : dateForInit);
                        }
                    } else {
                        this.set('select', now);
                    }

                    dataCollector.date = this.get('value');

                    output.val(dataCollector.date + ' ' + dataCollector.timeSlider);

                    timeSlider[0].noUiSlider.on('update', function (values, handle) {
                        timeSlider.find('.timeRangeValue').text(timeRangeValues[parseInt(values)]);

                        dataCollector.timeSlider = timeRangeValues[parseInt(values)];

                        var outputVal = hideTime == 'noTime' ? dataCollector.date : (dataCollector.date + ' ' + dataCollector.timeSlider);

                        output.val(outputVal);
                    });

                    // only trigger the picker update event on set event of the time slider
                    timeSlider[0].noUiSlider.on('set', function (values, handle) {
                        self.change();
                        checkTimeSliderForSameDay(self);
                    });
                    //prepopulate stuff

                },

                // when the value changes check the from/to dates and times
                onSet: function (ts) {
                    // console.log("Set value: ");
                    // console.dir(ts);
                    if (typeof ts.select === 'undefined') {
                        return;
                    }

                    let elem = this.$node;

                    // get self of parent -> used to find sibling date (used in a from/to scenario)
                    let wrapper = elem.closest('.datepicker_root');
                    let isStartDatePicker = wrapper.hasClass('start_date_picker');

                    // assume that this is the from element and the sibling is the end element
                    let inputStartDate = elem;
                    let inputEndDate = wrapper.siblings('.datepicker_root').find('.datepicker-input');

                    // start - end date pickers
                    if (inputEndDate.length > 0) {

                        // the assumption was wrong, so exchange the elements
                        if (!isStartDatePicker) {
                            inputStartDate = inputEndDate;
                            inputEndDate = elem;
                        }

                        let startDateTimeSlider = inputStartDate.siblings('.picker').find('.timerange-slider');
                        let endDateTimeSlider = inputEndDate.siblings('.picker').find('.timerange-slider');

                        let startDate = inputStartDate.pickadate('picker').get('select').pick;
                        let endDate = inputEndDate.pickadate('picker').get('select').pick;

                        // console.log("Start date : ");
                        // console.dir(startDate);
                        // console.log("End date : ");
                        // console.dir(endDate);
                        //
                        // console.log("Start date slider: ");
                        // console.dir(startDateTimeSlider);
                        // console.log("End date slider: ");
                        // console.dir(endDateTimeSlider);

                        if (isStartDatePicker === true) {
                            inputEndDate.pickadate('picker').set('min', new Date(ts.select));

                            var sd = new Date(ts.select);
                            // var startDate = moment.default(sd);

                            if (startDate > endDate) {
                                inputEndDate.pickadate('picker').set('select', new Date(sd.getFullYear(), sd.getMonth(), sd.getDate() + 3));
                            } else {
                                //Do nothing 
                            }
                        } else {
                            // If it's the same day as the start date also check the slider values
                            if (startDate === endDate) {
                                // console.log("We have the same day selected in the picker - make sure time for end is higher then time for start");
                                // let startTime = parent.timeRangeValues[parseInt(startDateTimeSlider[0].noUiSlider.get())];
                                // let endTime   = parent.timeRangeValues[parseInt(endDateTimeSlider[0].noUiSlider.get())];
                                let startTime = parseInt(startDateTimeSlider[0].noUiSlider.get());
                                let endTime = parseInt(endDateTimeSlider[0].noUiSlider.get());

                                // console.log("Start Slider:");
                                // console.log(startTime);
                                // console.log("End Slider:");
                                // console.log(endTime);

                                // only allow time after the start times
                                endDateTimeSlider[0].noUiSlider.updateOptions({
                                    padding: [startTime + 1, 0]
                                });

                                if (startTime >= endTime) {
                                    // set tie as start time +1 hour(4 steps)
                                    endDateTimeSlider[0].noUiSlider.set(startTime + 4);
                                }
                            } else {
                                // console.log("endDateTimeSlider[0].noUiSlider.options.padding");
                                // console.dir(endDateTimeSlider[0].noUiSlider.options.padding);
                                if (typeof endDateTimeSlider[0].noUiSlider.options.padding !== 'undefined' && endDateTimeSlider[0].noUiSlider.options.padding[0] != 0) {
                                    endDateTimeSlider[0].noUiSlider.updateOptions({
                                        padding: [0, 0]
                                    });
                                }
                            }
                        }
                    } // if(inputEndDate.length > 0)

                    if (this.get('value')) {
                        dataCollector.date = this.get('value');
                        var outputVal = hideTime == 'noTime' ? dataCollector.date : (dataCollector.date + ' ' + dataCollector.timeSlider);

                        output.val(outputVal);

                        // Uncomment to also open the other picker that has been changed by changing the value of this one
                        // dataCollector.iniData.afterInit && hideTime !== 'noTime' && setTimeout(this.open, 0);
                    }
                },

                onClose: function () {
                    dataCollector.pickerOpenStatus = false;
                },

                onOpen: function () {
                    dataCollector.pickerOpenStatus = true;
                    dataCollector.iniData.afterInit = true;
                }
            },
                picker,
                pickerApi;

            if (is_year_picker) {

                pickerOptions.max = true; // today
                pickerOptions.selectYears = 60;
            }

            picker = $(this).pickadate(pickerOptions);
            pickerApi = picker.pickadate('picker');

            self.siblings('.trigger-datepicker, .datetimepicker-output').on('click', function (event) {
                event.stopPropagation();

                if ($(this).hasClass('disabled')) {
                    console.log('is disabled');
                    return;
                }

                if (dataCollector.pickerOpenStatus && dataCollector.pickerOpenStatus === true) {
                    pickerApi.close();

                    return;
                }

                pickerApi.open();
            });
        });
    };

    return {
        init: initPickers,
        add: addPicker
    };

})();


function checkTimeSliderForSameDay(input) {
    let elem = input;
    let wrapper = elem.closest('.datepicker_root');
    let isStartDatePicker = wrapper.hasClass('start_date_picker');

    console.log("Is start date picker: " + isStartDatePicker);
    let inputStartDate = elem;
    let inputEndDate = wrapper.siblings('.datepicker_root').find('.datepicker-input');

    // The pickers are not ready yet - return
    if (inputEndDate.length == 0) return;

    if (!isStartDatePicker) {
        inputStartDate = wrapper.siblings('.datepicker_root').find(".datepicker-input");
        inputEndDate = elem;
    }

    let startDateTimeSlider = inputStartDate.siblings('.picker').find('.timerange-slider');
    let endDateTimeSlider = inputEndDate.siblings('.picker').find('.timerange-slider');

    let startDate = inputStartDate.pickadate('picker').get('select').pick;
    let endDate = inputEndDate.pickadate('picker').get('select').pick;

    // console.log("Is Start date picker: "+isStartDatePicker);

    if (isStartDatePicker) {
        // console.log("Start date:");
        // console.dir(startDate);
        // console.log("End date:");
        // console.dir(endDate);
        if (startDate === endDate) {
            // If the days match and we have hours enabled, make sure that the end hour is after the start at least by 3
            // console.log("We have the same day selected in the picker - make sure time for end is higher then time for start");
            // let startTime = parent.timeRangeValues[parseInt(startDateTimeSlider[0].noUiSlider.get())];
            // let endTime   = parent.timeRangeValues[parseInt(endDateTimeSlider[0].noUiSlider.get())];
            let startTime = parseInt(startDateTimeSlider[0].noUiSlider.get());
            let endTime = parseInt(endDateTimeSlider[0].noUiSlider.get());

            // console.log("Start Slider:");
            // console.log(startTime);
            // console.log("End Slider:");
            // console.log(endTime);

            // only allow time after the start times
            endDateTimeSlider[0].noUiSlider.updateOptions({
                padding: [startTime + 1, 0]
            });

            if (startTime >= endTime) {
                // set tie as start time +1 hour(4 steps)
                endDateTimeSlider[0].noUiSlider.set(startTime + 4);
            }
        } else {
            // console.log("endDateTimeSlider[0].noUiSlider.options.padding");
            // console.dir(endDateTimeSlider[0].noUiSlider.options.padding);
            if (typeof endDateTimeSlider[0].noUiSlider.options.padding !== 'undefined' && endDateTimeSlider[0].noUiSlider.options.padding[0] != 0) {
                endDateTimeSlider[0].noUiSlider.updateOptions({
                    padding: [0, 0]
                });
            }
        }
    }
}
