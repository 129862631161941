/*
* this function is used when a subarticle is added into a contract
* it analises the subarticles and provides the available ones
* we need:
*  article id, start date, end date, qty
*/
function get_next_subarticle_for_contract($this, position) {
	var promise = new Promise(function(resolve, reject) {

		var article = $this.closest(".artikel");

		var article_gnr = article.find(".article_gnr").val();

		var article_id = article.find(".artikel_id").val();
		//console.log('article_id: '+article_id);
		
		var $dates_wrapper = article.find(".td_dates > .dates_wrapper").last();

		var interval = get_interval_from_date_wrapper($dates_wrapper);
		let start_date = interval.start_date;
		let end_date = interval.end_date;

		var block_days_before = parseInt(article.find(".article_days_block_before").val());
		var block_days_after = parseInt(article.find(".article_days_block_after").val());

		var subarticles_already_in_contract = [];

		// first check which subarticles are already in contract, so we don't insert the same subarticle twice
		subarticles_already_in_contract = getSubarticlesAlreadyInContract(position);

		// console.log("subarticles_already_in_contract");
		// console.log(subarticles_already_in_contract);

		$.ajax({
			url: 'components/ArticleNumbers/ajax/ajax.php',
			type: 'POST',
			async: false,
			dataType: "json",
			data: {
				article_id: article_id,
				start_date: start_date,
				end_date: end_date,
				subarticles_already_in_contract: subarticles_already_in_contract,
				method: 'getNextSubarticleForContract',
				block_days_before: block_days_before,
				block_days_after: block_days_after
			},
			success: function(data) {

				// we get one subarticle back

				var next_subarticle_number = false;
				var next_subarticle_id = false;
				var article_is_not_available = data.article_is_not_available;

				// create the strings of subarticle_ids and subarticle_numbers
				$.each(data.article_numbers, function(idx, subarticle) {

					$.each(subarticle, function(id, number, not_available) {
						next_subarticle_id = id;
						next_subarticle_number = article_gnr+"-"+number;
					});
				});

				if(next_subarticle_id !== false) {
					resolve({
						subarticle_id: next_subarticle_id,
						subarticle_number: next_subarticle_number,
						subarticle_is_not_available: article_is_not_available,
						start_date: start_date,
						end_date: end_date,
					});
				}

				// prepare log variables
				var action = "get next subarticle for contract " + contract_id + " article_id " + article_id;
				saveLog(article_id, next_subarticle_id, action);
			}
		});
	});

	return promise;
}

// get an array of article numbers that are already in contract.
function getAllArticleNumbersAlreadyInContract() {

	var article_numbers = [];

	$(".article_gnr").each(function(index){
		article_numbers.push( $(this).val() );
	});

	return article_numbers;
}

// get an array of subarticles that are already in contract
function getAllSubarticleNumbersAlreadyInContract() {

	var subarticles_string = "";
	var subarticles = [];

	$.each($(".artikel_title .contract_articles_link"), function(i, value){
		console.log(value.text.substring(value.text.lastIndexOf("(")+1,value.text.lastIndexOf(")")));

		var current_article_subarticle_numbers = [];
		var article_number = $(this).closest(".artikel").find(".article_gnr").val();

		subarticles_string = value.text.substring(value.text.lastIndexOf("(")+1,value.text.lastIndexOf(")"));	// get them from article name
		current_article_subarticle_numbers = subarticles_string.split("; ");

		$.each(current_article_subarticle_numbers, function(i, sub_number){
			subarticles.push( sub_number );
		});
	});

	return subarticles;
}

// get an array of subarticles that are already in contract, coming from a specified article
function getSubarticlesAlreadyInContract(position) {

	var subarticles_string = "";
	var subarticles = [];

	subarticles_string = $("#subarticle_ids_"+position).val();
	subarticles.push( subarticles_string.split(";") );

	return subarticles;
}

/*
* check if this is a subarticle, and if true, return an array "id_article" => "id_subarticle" or as separate values
*/
function checkIfSubarticleInContract(subarticle_number) {
	// rc: not used
	//console.log("scanned article nr: "+subarticle_number);

	var article_ids = [];

	$.ajax({
		url: 'components/ArticleNumbers/ajax/ajax.php',
		type: 'POST',
		async: false,
		dataType: "json",
		data: {
			subarticle_number: subarticle_number,
			id_contract: $('#id_contract').val(),
			method: 'checkIfSubarticleInContract'
		},
		success: function(data) {
			//console.log('article_ids from scan return: '+data.toSource());
			article_ids = data.article_ids;
		}
	});

	return article_ids;
}

function checkIfSubarticleIsAvailable(contract_id, start_date, end_date, subarticle_id, block_days_before, block_days_after, cb) {

	$.ajax({
		url: 'components/ArticleNumbers/ajax/ajax.php',
		type: 'POST',
		dataType: "json",
		data: {
			contract_id: contract_id,
			start_date: start_date,
			end_date: end_date,
			subarticle_id: subarticle_id,
			block_days_before: block_days_before,
			block_days_after: block_days_after,
			method: 'isSubarticleAvailable'
		},
		success: function(data) {
			if(data.success) {
				cb(data.is_subarticle_available);
			}
		}
	});
}

function checkIfArticleIsAvailable(contract_id, start_date, end_date, article_id, count, block_days_before, block_days_after, cb) {

	$.ajax({
		url: 'components/AvailabilityCheck/ajax/ajax.php',
		type: 'POST',
		dataType: "json",
		data: {
			contract_id: contract_id,
			start_date: start_date,
			end_date: end_date,
			article_id: article_id,
			count: count,
			block_days_before: block_days_before,
			block_days_after: block_days_after,
			method: 'isArticleAvailable'
		},
		success: function(data) {
			if(data.success) {
				cb(data.is_available);
			}
		}
	});
}

/*
* find corresponding return input for this subarticle
* also avoid duplicates
* return input id
*/
function findSubarticleReturnInput(return_id_sub) {

	var current_article = "";
	var current_article_parent = "";
	var current_article_sub_ids_string = "";
	var current_article_sub_ids = [];
	var target_return_artikel_sub_ids_string = "";
	var target_return_artikel_sub_ids = [];

	var return_input_id = "";

	// after we got article and subarticle ids we need to find this subarticle in contract
	$('.artikel_id').each(function(){	// get corresponding subarticle
		//console.log('artikel_id val: '+$(this).val());
		if (return_id_sub.article_id == $(this).val()) {	// we found the article

			current_article        = $(this);
			current_article_parent = current_article.closest('tr'); // row
			current_article_sub_ids_string = current_article_parent.find('.subarticle_ids').val();
			current_article_sub_ids = current_article_sub_ids_string.split(";");
			if ($.inArray(return_id_sub.subarticle_id, current_article_sub_ids) >= 0) {	// we found the subarticle

				// now we check if the subarticle was scanned before (fresh, and already returned)
				target_return_artikel_sub_ids_string = current_article_parent.find('.return_artikel_sub_ids').val();
				target_return_artikel_sub_ids = target_return_artikel_sub_ids_string.split(";");

				target_already_returned_artikel_sub_ids_string = current_article_parent.find('.already_returned_artikel_sub_ids').val();
				target_already_returned_artikel_sub_ids = target_already_returned_artikel_sub_ids_string.split(";");

				if ($.inArray(return_id_sub.subarticle_id, target_return_artikel_sub_ids) >= 0 ||
					$.inArray(return_id_sub.subarticle_id, target_already_returned_artikel_sub_ids) >= 0) {
					//console.log('blocked duplicate subarticle return');
				} else {
					return_input_id = current_article_parent.find('.return_artikel_sub_ids').attr('id');
				}
			}
		}
	});

	return return_input_id;
}


$(document).ready(function() {
	let subCounter = 0;
	let tempids = [];

	$(document).on('click', '#add_subarticle_number', function() {

		tempids = $('#new_subarticle_ids').val().split(',');
        // Adds 1 to Lagerbestand

        $('#articleQty').val( function(i, oldval) {
            return ++oldval;
        });

        // Creates new Sub-article

		let serieNr;
		let subArticleTable = $('#subArticleTable').DataTable();
		let articleGNR = $('#articleNumber').val();

		if(subArticleTable.data().count() === 0) {

			serieNr = '1';
		} else {
			var maxSubarticleNumber = 0; 
			$("input.subarticle-number").each(function() { maxSubarticleNumber = Math.max(maxSubarticleNumber, parseInt($(this).val())) });

			serieNr = maxSubarticleNumber + 1;
		}

		subCounter ++;
		let tempSubId = 'new_subId_'+subCounter ;

		tempids.push(tempSubId);
		$('#new_subarticle_ids').val(tempids);

		subArticleAdd(tempSubId, serieNr, articleGNR);
	});

   $(document).on('click','#articleHasSubarticles',function() {
		var subs_visible = true;

   		if($('#articleHasSubarticles').is(':checked')) {
            $("label[for='articleHasSubarticles']").removeClass('color-warning');
   			$('.subArticlesContainer').removeAttr('hidden');

   			let qty = $('#articleQty').val();
			let serieNr = 0;

			// generate sub articles rows
   			if(qty > 0 && $('#subArticleTable').DataTable().data().count() < qty){
				let leftToAdd = qty - $('#subArticleTable').DataTable().data().count();

				for (let i = 0, p = Promise.resolve(); i < leftToAdd; i++) {
					p = p.then(_ => new Promise(resolve =>
						setTimeout(function () {

							let tempSubId = 'new_subId_' + subCounter;
							let articleGNR = $('#articleNumber').val();
							subCounter ++;
							serieNr++;
							tempids.push(tempSubId);
							$('#new_subarticle_ids').val(tempids);

							subArticleAdd(tempSubId, serieNr, articleGNR,
								() => {
									resolve();
								});
						}, 0)
					));
				}
			}

			// also disable the stock count
			$('#articleQty').attr('readonly', '')

   		} else if (! $('#articleHasSubarticles').is(':checked') && ! $('#subArticleTable').DataTable().data().count()) {
			// if has subs checkbox is not selected and there are no subs declared
            $("label[for='articleHasSubarticles']").removeClass('color-warning');
			$('.subArticlesContainer').attr('hidden',true);
			
			$('#articleQty').removeAttr('readonly');

			subs_visible = false
		} else {
			// tries to uncheck the has subs checkbox, but subs are already defined - they need to be manually deleted first
            $('#articleHasSubarticles').prop('checked', true);
   			$("label[for='articleHasSubarticles']").addClass('color-warning');
   			$('#articleHasSubarticles').parent().attr('title','Nicht änderbar, da Artikel bereits in Anfragen / Reservierungen verwendet wird');
		}

		// hide the select for warehouse location on article level if subs are showing
		if(subs_visible) {
			$("#warehouse_location_at_article_level").hide();

			// removes selection
			$("#warehouse_location").val(0);
		} else {
			$("#warehouse_location_at_article_level").show();
		}
   });

	// If the subarticle numbers are generated, do not allow any LAGER changes. Only from the Artikelnummern tab
	// show & hide popup message
	$("#article_lager").click(function () {
		var subart_count = $('#subart_count').val();

		if (subart_count > 0) {
			$('.lager-readonly-message').toggle().delay(7000).fadeOut('slow');
		}
	});
});


function subArticleAdd(subArticleId, serieNr, articleGNR, cb = () => {}) {

    $.ajax({
        url : 'includes/artikel/ajax/SubArticleRow.php',
        type: 'POST',
        data: {
			subArticleId : subArticleId.trim(),
			serieNr : serieNr,
			articleGNR : articleGNR,
            method: 'reloadSubArticles'
        },
        success: function(data) {

        	let obj = $(data);

			let table = $('#subArticleTable').DataTable();
			table.row.add(obj);
			table.order([0, 'desc']);

			table.draw();

			enableSaveButton('#save_article');

			cb();
        }
    });

}


$(document).on('click', '#export_ean_codes', function(e) {
	e.preventDefault();

	let articleId = $('#articleId').val();

	window.open('includes/artikel/ajax/eanExport.php?id='+articleId, '_blank');

	return false;
});

$(document).on('input','#articleNumber',function () {

	if($('#articleNumber').val().length > 0){

		$('#articleHasSubarticles').removeAttr('disabled');
		$('.hasSubCheckbox').removeAttr('title');
	}else {
		$('#articleHasSubarticles').attr('disabled', 'disabled');
		$('.hasSubCheckbox').attr('title','Bitte geben Sie zuerst eine Basisartikelnummer ein.');
	}
});

// export section
window.get_next_subarticle_for_contract = get_next_subarticle_for_contract;
window.checkIfSubarticleIsAvailable = checkIfSubarticleIsAvailable;
window.checkIfArticleIsAvailable = checkIfArticleIsAvailable;