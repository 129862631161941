/* global $ */

const MULTI_LANG_SORT = 'multi_lang_sort';
function initSortFunctions()
{
    const sort_key_asc = MULTI_LANG_SORT + '-asc';
    const sort_key_desc = MULTI_LANG_SORT + '-desc';

    if(typeof($.fn.dataTableExt.oSort[sort_key_asc]) === 'undefined') {
        $.fn.dataTableExt.oSort[sort_key_asc] = function(x,y) {
            return x.toString().localeCompare(y.toString());
        };

        $.fn.dataTableExt.oSort[sort_key_desc] = function(x,y) {
            return y.toString().localeCompare(x.toString());
        };
    }
}

function setDataTablesOptions($this, extraFilters) {
    initSortFunctions();

    // todo: make a more general mechanism for disabling pagination
    const $table = $this.find('table');
    const table_id = $table.first().attr('id');
    
    const disable_options = (table_id == 'subArticleTable') || (table_id == 'tr_fill') || (table_id == 'no_fill'); 

    var disable_pagination = disable_options;
    var disable_searching = disable_options;
    var disable_sorting = disable_options;

    const isCustomerTable = table_id == 'customerTable';

    var tableTitle = $table.data('table-name'),
        tableSubTitle = $table.data('table-subtitle'),
        searchIcon = '<svg class="icon search_icon"><use xlink:href="#icon-search"></use></svg>',
        // actionButton = '<button type="button" class="data-table-footer-action-button button-dash-action reset-button primary-ff text-uppercase">Zeige mehr</button>',
        previousButton = '<svg class="icon" style="width: 10px; height: 8px;"><use xlink:href="#icon-arrow-left"></use></svg>',
        nextButton = '<svg class="icon" style="width: 10px; height: 8px;"><use xlink:href="#icon-arrow-right"></use></svg>',
        // removeFooter = !!$this.hasClass('data-table-block-extended'),
        removeFooter = !!$this.hasClass('data-table-no-footer'),
        customScrollBar = !!$this.hasClass('data-table-block-extended-fixed-height'),
        performanceLimit = !!$this.hasClass('data-table-block-performance-limit'),
        performanceRes = !!$this.hasClass('data-table-block-performance-res'),
        performanceRet = !!$this.hasClass('data-table-block-performance-ret'),
        performanceResUber = !!$this.hasClass('data-table-block-performance-res-uber'),
        performanceAus = !!$this.hasClass('data-table-block-performance-aus'),
        performanceArt = !!$this.hasClass('data-table-block-performance-art'),
        performanceArtCrit = !!$this.hasClass('data-table-block-performance-art-crit'),
        performanceAddArt = !!$this.hasClass('data-table-block-performance-add-art'),
        searchPlaceholder = !!$this.data('search-placeholder') ? $this.data('search-placeholder') : 'Suche',
        hasDefaultFilter = !!$this.hasClass('data-table-block-default-filter'),
        languageSettings = {
            searchPlaceholder: searchPlaceholder,
            sLengthMenu: '<span class="data-tables-dropdown-select">Zeige</span> _MENU_',
            sEmptyTable: 'Keine Daten in der Tabelle vorhanden',
            sZeroRecords: 'Keine Einträge gefunden',
            sInfo: 'Anzeige _START_ - _END_ von _TOTAL_',
            sInfoEmpty: '0 bis 0 von 0 Einträgen',
            sSearch: '',
            oPaginate: {
                sNext: nextButton,
                sPrevious: previousButton
            }
        },
        tableLayout = '<"data-table-header dash-block-header flex flex__align--center"fl>' +
            '<"data-table-content"t>' +
            '<"data-table-footer flex flex__align--center"ip>',
        dataTablesOptions = {};

    let $extrasForHeader = false;
    if($this.find(".extras_for_header").length > 0) {
        $extrasForHeader = $this.find(".extras_for_header");
    }

    if (removeFooter) {
        tableLayout = '<"data-table-header dash-block-header flex flex__align--center"fl>' +
            '<"data-table-content"t>';
    }

    dataTablesOptions = {
        autoWidth: false,
        responsive: true,
        dom: tableLayout,
        pagingType: 'simple_numbers',
        language: languageSettings,
        stateSave: false,
        paging: true,

        stateSaveCallback: function (settings, data) {

            localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data))
        },
        stateLoadCallback: function (settings) {

            return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance))
        },

        initComplete: function () {

            var tableParent = $(this).parents('.dataTables_wrapper');

            let subtitle = typeof(tableSubTitle) != 'undefined' ? '<span class="text-normal">' + tableSubTitle + '</span>' : '';
            //add table name
            let $tableHeader = tableParent.find('.data-table-header');
            
            // the extras are added to some tables as a div with class extras_for_header
            if($extrasForHeader !== false) {
                $tableHeader.prepend($extrasForHeader);
            }

            $tableHeader.prepend('<div class="data-table-name text-uppercase"> ' + tableTitle + subtitle +'</div>');

            // console.log('table titles is -->', tableTitle, tableParent.length, tableParent.find('.data-table-header').length);

            var data = tableParent.data() || {};

            // add search icon & customize search placeholder
            tableParent.find('.dataTables_filter').append(searchIcon);

            // click on the search icon would generate a search/filter by sending the enter key
            var on_click_search = function () {
                var e = jQuery.Event("keypress");
                e.which = 13;
                e.keyCode = 13;

                var search_field = tableParent.find('.dataTables_filter input[type=search]');
                search_field.focus();
                search_field.trigger(e);

            }

            tableParent.find('.dataTables_filter .search_icon').on('click', function (e) { e.preventDefault(); on_click_search(); return false; });

            if (!hasDefaultFilter) {
                tableParent.find('.dataTables_filter input').unbind();
            }

            //add action button into table footer - RE-ENABLE again later on
            //tableParent.find('.dataTables_info').after(actionButton);
        }
    };

    if (customScrollBar) {
        // dataTablesOptions.scrollY = '300px';
        dataTablesOptions.scrollX = false;
        dataTablesOptions.scrollCollapse = true;
        dataTablesOptions.iDisplayLength = -1;
        dataTablesOptions.lengthMenu = [[5, 10, 25, 50, -1], [5, 10, 25, 50, "Alle"]];
    }


    // TODO - DataTable Classes can be reused a bit in the future


    if (performanceLimit) {

        let nrOfArticles = $('#articleNr').val();

        if (!nrOfArticles) {
            nrOfArticles = 0;
        }

        dataTablesOptions.paging = false;
        // dataTablesOptions.scrollY = '425px';
        dataTablesOptions.language.sInfo = ' ';
        dataTablesOptions.language.sInfoEmpty = ' ';
        dataTablesOptions.language.infoFiltered = ' ';
        dataTablesOptions.language.infoPostFix = ""; // 'Insgesamt ' + nrOfArticles + ' Artikel. Bitte verwenden Sie die Suche ';
        dataTablesOptions.search = false;

    }

    if (performanceRes) {

        let loanCount = $('.count-loan').eq(0).attr('data-count');

        if (!loanCount) {
            loanCount = 0;
        }

        dataTablesOptions.paging = false;
        dataTablesOptions.autoWidth = true;
        // dataTablesOptions.scrollY = '300px';
        dataTablesOptions.language.sInfo = ' ';
        dataTablesOptions.language.sInfoEmpty = ' ';
        dataTablesOptions.language.infoFiltered = ' ';
        dataTablesOptions.language.infoPostFix = ""; // 'Insgesamt ' + loanCount + ' Ausleihen. Bitte verwenden Sie die Suche ';
        // dataTablesOptions.search = false;


    }

    if (performanceRet) {

        let returnCount = $('.ret-count').eq(0).attr('data-count');

        if (!returnCount) {
            returnCount = 0;
        }

        dataTablesOptions.paging = false;
        dataTablesOptions.autoWidth = true;
        // dataTablesOptions.scrollY = '300px';
        dataTablesOptions.language.sInfo = ' ';
        dataTablesOptions.language.sInfoEmpty = ' ';
        dataTablesOptions.language.infoFiltered = ' ';
        dataTablesOptions.language.infoPostFix = ""; // 'Insgesamt ' + returnCount + ' Ausleihen. Bitte verwenden Sie die Suche ';
        // dataTablesOptions.search = false;

    }



    if (performanceAus) {

        let ausCount = $('.add-customer, .count-aus').eq(0).attr('data-count');

        if (!ausCount) {
            ausCount = 0;
        }

        dataTablesOptions.paging = false;
        // dataTablesOptions.scrollY = '600px';
        dataTablesOptions.scrollCollapse = true;
        dataTablesOptions.language.sInfo = ' ';
        dataTablesOptions.language.sInfoEmpty = ' ';
        dataTablesOptions.language.infoFiltered = ' ';
        dataTablesOptions.language.infoPostFix = ""; // 'Insgesamt '+ ausCount + ' Ausleiher. Bitte verwenden Sie die Suche ';


    }

    if (performanceArt) {

        let artCount = $('.count-art').eq(0).attr('data-count');

        if (!artCount) {
            artCount = 0;
        }

        dataTablesOptions.paging = false;
        // dataTablesOptions.scrollY = '800px';
        dataTablesOptions.language.sInfo = ' ';
        dataTablesOptions.language.sInfoEmpty = ' ';
        dataTablesOptions.language.infoFiltered = ' ';
        dataTablesOptions.language.infoPostFix = ""; // 'Insgesamt '+ artCount +' Artikel. Bitte verwenden Sie die Suche ';
    }

    if (performanceArtCrit) {

        let artCount = $('.count-art').eq(0).attr('data-count');

        if (!artCount) {
            artCount = 0;
        }

        dataTablesOptions.paging = false;
        // dataTablesOptions.scrollY = '300px';
        dataTablesOptions.language.sInfo = ' ';
        dataTablesOptions.language.sInfoEmpty = ' ';
        dataTablesOptions.language.infoFiltered = ' ';
        dataTablesOptions.language.infoPostFix = ""; // 'Insgesamt '+ artCount +' Artikel. Bitte verwenden Sie die Suche ';
    }

    if (performanceResUber) {

        let conCount = $('.count-con').eq(0).attr('data-count');
        let conType = $('.count-con').eq(0).attr('data-type');

        if (!conCount) {
            conCount = 0;
        }

        let type = '';
        if (conType == 2) {
            type = 'Anfragen';
        } else if (conType == 3) {
            type = 'Reservierungen';
        } else {
            type = 'Verträge';
        }

        dataTablesOptions.paging = false;
        // dataTablesOptions.scrollY = '250px';
        dataTablesOptions.language.sInfo = ' ';
        dataTablesOptions.language.sInfoEmpty = ' ';
        dataTablesOptions.language.infoFiltered = ' ';
        dataTablesOptions.language.infoPostFix = ""; // 'Insgesamt '+ conCount +' '+ type +'. Bitte verwenden Sie die Suche ';
    }


    if (performanceAddArt) {

        let artCount = $('.add-article').eq(0).attr('data-count');

        if (!artCount) {
            artCount = 0;
        }

        dataTablesOptions.paging = false;
        // dataTablesOptions.scrollY = '400px';
        dataTablesOptions.language.sInfo = ' ';
        dataTablesOptions.language.sInfoEmpty = ' ';
        dataTablesOptions.language.infoFiltered = ' ';
        dataTablesOptions.language.infoPostFix = ""; // 'Insgesamt '+ artCount +' Artikel. Bitte verwenden Sie die Suche ';
    }

    //dataTablesOptions.columnDefs = [{targets:'_all', type: MULTI_LANG_SORT}];
    dataTablesOptions.columnDefs = [];
    $this.find( "thead tr th" ).each(function( index ) {
        switch($( this ).data('fieldtype')) {
            // Sort including special language characters
            // Others can be added here
            case 'multi_lang_sort':
                dataTablesOptions.columnDefs.push({
                    "type": "multi_lang_sort",
                    "targets": [index]
                });
                break;
        }
    });

    if (extraFilters) {
        dataTablesOptions.columnDefs.push({
            "targets": [4],
            "visible": false,
            "searchable": true
        });

        dataTablesOptions.columnDefs.push({
            "targets": [5],
            "visible": false,
            "searchable": true
        });

        // console.log(dataTablesOptions.columnDefs);
    }

    if (disable_pagination) {
        dataTablesOptions.paging = false;
    }

    if (disable_searching) {
        dataTablesOptions.searching = false;
    }

    if (disable_sorting) {
        dataTablesOptions.ordering = false;
    }

    if(isCustomerTable) {
        // sort by customer name
        dataTablesOptions.order = [[1, 'asc']];
    }

    return dataTablesOptions;
}

function initDataTableBlock() {

    var extraFilters = !!$(this).hasClass('data-table-extra-filters');

    var dataTablesOptions = setDataTablesOptions($(this), extraFilters);

    var dataTableApi = $(this).find('table').DataTable(dataTablesOptions);

    if (extraFilters) {

        var placeholders = $(this).find('.table-stats-extra-filters').clone();

        $(this).find('.table-stats-extra-filters').remove().detach();
        $(this).find('.data-table-content').prepend(placeholders);
        
        // var years = [],
            // months = [],            
            // monthsData = dataTableApi.columns('.table-stats-month-data').data()[0],
            // yearsData = dataTableApi.columns('.table-stats-year-data').data()[0],
            // monthsDataLength = (monthsData || []).length,
            // yearsDataLength = (yearsData || []).length;

        // for (var i = 0; i < monthsDataLength; i++) {
        //     if (months.indexOf(monthsData[i]) === -1) {
        //         months.push(monthsData[i]);

        //         if (i === 0) {
        //             $('#table-stats-month-data').append('<option value="">Month</option>');
        //         }
        //         $('#table-stats-month-data').append('<option value="' + monthsData[i] + '">' + monthsData[i] + '</option>');
        //     }
        // }

        // for (var i = 0; i < yearsDataLength; i++) {
        //     console.log('sadfass')
        //     if (years.indexOf(yearsData[i]) === -1) {
        //         years.push(yearsData[i]);

        //         if (i === 0) {
        //             $('#table-stats-year-data').append('<option value="">Year</option>');
        //         }
        //         $('#table-stats-year-data').append('<option value="' + yearsData[i] + '">' + yearsData[i] + '</option>');
        //     }
        // }

        /*
         trigger custom event after filters are added
         */
        $(document).trigger('extra_filters_finished');

        $('.data-table-block-extra-filter').on('change', function () {
            var val = $.fn.dataTable.util.escapeRegex($(this).val());

            dataTableApi.columns('.' + $(this).attr('id'))
                .search(val ? '^' + val + '$' : '', true, false)
                .draw();
        });


    }
}

function showPreload() {

    $('.preload').removeClass('preload-hide');
}

function hidePreload() {

    $('.preload').addClass('preload-hide');
}

$(document).ready(function () {
    $('.data-table-block').each(initDataTableBlock);

    $(document)
        //dropdown trigger main menu
        .on('click', '.trigger-dropdown', function () {
            // if ($(this).parents('li').hasClass('main-nav-activated')) {
            //  $(this).parents('li').removeClass('main-nav-activated');
            // } else {
            //  $(this).parents('li').addClass('main-nav-activated');
            // }

            //$(this).parents('li').siblings().removeClass('main-nav-activated');
        })

        //trigger left menu show / hide main nav
        .on('click', '.trigger-main-menu', function () {
            $('body').toggleClass('nav-opened');
        });



    // ................................
    // hide main menu on resize / small
    // ................................
    function hideMenu(minWidth) {
        if (minWidth === undefined || minWidth === null) {
            minWidth = 1200;
        }
        //console.log('minWidth: '+minWidth);

        if (window.innerWidth >= minWidth) {
            $('body').addClass('nav-opened');
        } else {
            $('body').removeClass('nav-opened');
        }

        //Andy: Adjust the datatables so that the header remains in sync with the data rows
        // it does not align perfectly but it's close enough for now - latest datatables version might help
        $($.fn.dataTable.tables(true)).DataTable().columns.adjust();
    }
    hideMenu();
    window.onresize = function () { hideMenu(); }
    // ................................

    //disable preloader after a while
    setTimeout(function () {

        hidePreload();

    }, 500);

});

// export section
window.setDataTablesOptions = setDataTablesOptions;
window.initDataTableBlock = initDataTableBlock;