

quickverleih = quickverleih || {}

quickverleih.reservations = (function () {
    // enable end button only if all article are returned
    var enable_end_button = function() {
        let all_articles_returned = true;

        $(".sub_stat").each(
            function(i, status_select) {
                if($(status_select).val() !== 'stat_zur') {
                    all_articles_returned = false;
                    return false;
                }
            }
        );

        if(all_articles_returned) {
            $(".art_stat").each(
                function (i, status_select) {
                    if ($(status_select).val() !== 'stat_zur') {
                        all_articles_returned = false;
                        return false;
                    }
                }
            );
        }

        if(all_articles_returned) {
            $('#beendenButton').removeClass('disabled');
        }
    }

    var init_anonymize_button = function() {
        $('#anonymise_contract').on('click', function(e) {
            e.preventDefault();

            let contractId = $('#contract_id').val();

            $.ajax({
                url: 'includes/kunden/ajax/ajaxCustomer.php',
                type: 'POST',
                data: {
                    contractId: contractId,
                    method: 'makeCustomerAnonym'
                },
                success: function(data) {
                    window.location.reload(true); 
                }
            });

            return false;
        });
    }

    return {
        enable_end_button: enable_end_button,
        init_anonymize_button: init_anonymize_button
    }
})();

// On Select All (ArtikelNr) check/unckeck

$(document).on('click','#stat_all',function () {
    let is_checked = $('#stat_all').is(":checked");

    $(this).closest('#contractArticlesTable').find('.the_stat:enabled').each(function () {
        $(this).prop('checked', is_checked);
    });

});

// L002 AC0011-5

// On scan Submit
$(document).on('click','#articleScanSubmit', function() {

    let error = false;
    let notFound = [];
    let articleArray = '';
    let scannedItems = $('#scannedArticle').val().replace(/\s/g,''); // removes spaces

    articleArray = scannedItems.split(';');

    if(articleArray.length > 0) {

        articleArray.forEach(function (article) {

            // the filter function makes sure that only the label matching exactly the searched text are used
            let check = $('.td_checks')
                .find("label:contains("+article+")")
                .filter((a,e) => {return e.innerText === article});

            if(check.length > 0){

                check.siblings('.the_stat').prop('checked',true);

            }else if (article.length > 0) {

                error = true;
                notFound.push(article + ', ');
            }
        });

        if (error === false){
            closePopup();
        }else{

            $('#error_scan_handout_articles_numbers').html(notFound).show();
            $('#error_scan_handout_articles').show();
        }
    }
});


// Adding scanned articles

function articleScanInput() {

    let updated = $('#scannedArticle').val() + ';\n';
    $('#scannedArticle').val(updated);
};

$(document).on('input','#scannedArticle', _.debounce(articleScanInput, 250));


/**
 * Hand out article by button
 */
$(document).on('click','#handOutArticle',function () {

    $('.the_stat:checkbox:checked').each(function () {

        $article = $(this).closest('.article');

        let index = $(this).closest('.theSub').index();
        let $return_anzahl = $article.find('.return_anzahl');
        let subArticleId = $article.find('.td_checks > .theSub').eq(index).find('input').attr('data-subarticle_id');
        
        /**
         * ----  if Subarticle
         */

        if(typeof(subArticleId) !== 'undefined') {

            $article.find('.td_status > .theSub').eq(index).find('.sub_stat').val('stat_aus').change();
            $article.find('.td_numb_2 > .theSub').eq(index).find('.return_anzahl_sub').val('');
            $article.find('.td_r-date > .theSub').eq(index).find('.return_date_input').val('');

            // Remove from Returned array -----------------------------------------------------------------------------------

            let $return_artikel_sub_ids = $article.find('.return_artikel_sub_ids');

            if($return_artikel_sub_ids.val()){

                if ($return_artikel_sub_ids.val().length > 0) {

                    let array = $return_artikel_sub_ids.val().split(';');
                    // check if returned
                    if (array.indexOf(subArticleId) !== -1) {

                        let removeIndex = array.indexOf(subArticleId);
                        array.splice(removeIndex, 1);
                        $return_artikel_sub_ids.val(array.join(";"));
                        $return_anzahl.val(array.length);
                    }
                }
            }

            // mark as handedout
            let $handed_out_subs = $article.find('.article-handed-out-subs');
            let handed_out_subs_val = $handed_out_subs.val();

            if(handed_out_subs_val.length > 0 && handed_out_subs_val != '0'){
                let array_of_handed_out = handed_out_subs_val.split(';');

                // do not duplicate
                if(array_of_handed_out.indexOf(subArticleId) == -1) {
                    array_of_handed_out.push(subArticleId);
                    $handed_out_subs.val(array_of_handed_out.join(";"));
                }
            } else {
                $handed_out_subs.val(subArticleId);
            }

            /**
             * ----  if normal Article
             */

        } else {

                $article.find('.td_status').find('.art_stat').val('stat_aus').change();
                $article.find('.td_numb_2').find('.theSub > input').val('');
                $article.find('.td_r-date').find('.return_date_input').val('');

                $return_anzahl.val(0);

            // mark as handedout
            let $article_handed_out = $article.find('.article-handed-out');
            $article_handed_out.val(1);
        }

        $(this).prop('checked',false);
    });

    $("#stat_all").prop('checked',false);
});

// get today's date
function theDate (){

    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth()+1;
    let yy = today.getFullYear().toString();
    if(dd<10) {
        dd = '0'+dd
    }
    if(mm<10) {
        mm = '0'+mm
    }
    today = dd + '.' + mm + '.' + yy;

    return today;

}

/**
 *  ------- Return article with button
 */
$(document).on('click', '#returnStockButton',function () {

    let html = `<div class="dash-block-header">
    					<div class="message-header-title text-uppercase">Möchten Sie den Lagerbestand gutschreiben?</div>
					</div>
					<div class="popup-content">
					<br>
					<p><b>Nachdem der Status auf "zurück" gesetzt wurde und Sie den "SPEICHERN" Button geklickt haben, können Sie für den gewählten Artikel den Status nicht mehr verändern.</b></p>
					<br>
   						<div class="button submit-button return-item-yes" data-id="">OK</div>
    					<a href="#close-modal" rel="modal:close">
        					<div class="button white-button return-item-no" style="float: right">ABBRECHEN</div>
    					</a>
					</div>`;

    openPopup(html, '500px');

        $(document).on('click','.return-item-yes',function () {

            $('.the_stat:checkbox:checked').each(function () {

                // let today = theDate();
                let index = $(this).closest('.theSub').index();
                let $article = $(this).closest('.article');
                var $return_anzahl = $article.find('.return_anzahl');

                $(this).closest('.article').find('.td_status > .theSub').eq(index).find('.sub_stat').val('stat_zur');
                $(this).closest('.article').find('.td_numb_2 > .theSub').eq(index).find('.return_anzahl_sub').val(1);
                $(this).closest('.article').find('.td_r-date > .theSub').eq(index).find('.return_date_input').val(theDate());

                let subArticleId = $(this).closest('.article').find('.td_checks > .theSub').eq(index).find('input').attr('data-subarticle_id');

                /**
                 * check if subarticle or not
                 */

                if(typeof(subArticleId) !== 'undefined') {

                    let $return_artikel_sub_ids = $article.find('.return_artikel_sub_ids');

                    if($return_artikel_sub_ids.val().length > 0){

                        let array = $return_artikel_sub_ids.val().split(';');
                        // do not duplicate
                        if(array.indexOf(subArticleId) == -1) {

                            array.push(subArticleId);
                            $return_artikel_sub_ids.val(array.join(";"));
                            $return_anzahl.val(array.length);

                            $(this).closest('.article').find('.td_r-date > .theSub').eq(index).val(1);
                            $(this).closest('.article').find('.td_numb_2 > .theSub').eq(index).find('.return_anzahl').val(theDate());
                        }
                    }else{

                        $return_artikel_sub_ids.val(subArticleId);
                        $return_anzahl.val(1);

                        $(this).closest('.article').find('.td_status > .theSub').eq(index).find('.sub_stat').val('stat_zur');
                        $(this).closest('.article').find('.td_numb_2 > .theSub').eq(index).find('.return_anzahl_sub').val(1);
                        $(this).closest('.article').find('.td_r-date > .theSub').eq(index).find('.return_date_input').val(theDate());
                    }

                    /**
                     * if not subarticle
                     */

                } else {

                    let $artikel_anzahl = $article.find('.artikel_anzahl');

                    let returnVal = $article.find('.return_anzahl_view').val();
                    if (returnVal === '') {
                        $article.find('.return_anzahl_view').val($artikel_anzahl.val());
                        $return_anzahl.val($artikel_anzahl.val());
                    }else {
                        $return_anzahl.val(returnVal);
                    }

                    $(this).closest('.article').find('.td_r-date > .theSub').eq(index).val(1);
                    $(this).closest('.article').find('.td_numb_2 > .theSub').eq(index).find('.return_anzahl').val(theDate());
                    $(this).closest('.article').find('.td_numb_2 > .return_anzahl').val(1);
                }

                if($(this).closest('.article').find('.td_numb_2 > .return_anzahl_view').length > 0){

                    let leftAus = $(this).closest('.article').find('.td_numb_2 > .to_return_anzahl_view');
                    let itemNr = $(this).closest('.article').find('.td_numb_1 > .artikel_anzahl').val();
                    let zurNr = $(this).closest('.article').find('.td_numb_2 > .return_anzahl_view').val();

                    if (itemNr - zurNr > 0 && zurNr && zurNr > 0){
                        $(this).closest('.article').find('.td_numb_2 > .return_anzahl_view').val(zurNr);

                        let zurLeft = itemNr - zurNr;

                        let zurLeftHTML = `<input
                                        type="text"
                                        value=${zurLeft}
                                        data-value=${zurLeft}
                                        class="to_return_anzahl_view width-55 text-grays"
                                        style="margin-top: 10px"
                                        name="to_return_anzahl_view"
                                        id="to_return_anzahl_view_[]"
                                        readonly
                                       />`;

                        $(this).closest('.article').find('.td_numb_2 > .return_anzahl_view').after(zurLeftHTML);
                        $(this).closest('.article').find('.art_stat').val('stat_aus');


                    }else if (leftAus && itemNr === zurNr ){

                        leftAus.val(0);
                        $(this).closest('.article').find('.art_stat').val('stat_zur');

                        let itemNr = $(this).closest('.article').find('.td_numb_1 > .artikel_anzahl').val();
                        $(this).closest('.article').find('.td_numb_2 > .return_anzahl').val(itemNr);

                    }else if(leftAus && itemNr > zurNr){

                        leftAus.val(itemNr - zurNr);
                        $(this).closest('.article').find('.art_stat').val('stat_aus');

                    }
                    // else {
                    //
                    //
                    //     $(this).closest('.article').find('.td_numb_2 > .return_anzahl_view').val(itemNr);
                    //     $(this).closest('.article').find('.art_stat').val('stat_zur');
                    //     console.log('max return');
                    // }


                    // $(this).closest('.article').find('.td_numb_2 > .return_anzahl_view').val(itemNr);
                    $(this).closest('.article').find('.td_r-date > .theSub').find('.return_date_input').val(theDate());
                    // $(this).closest('.article').find('.art_stat').val('stat_zur');
                }
                $(this).prop('checked',false);
            });

            closePopup();

            $("#stat_all").prop('checked',false);
        });
});




$(document).on('input','.return_anzahl_view',function () {

    let itemNr = parseInt($(this).closest('.article').find('.artikel_anzahl').val());
    let userInput = parseInt($(this).val());
    
    if (0 < userInput && userInput <= itemNr || $(this).val().length === 0 ){

        $(this).css('border-color','#eaeaea');
        $(this).removeClass('warning-error');
        enableSaveButton('#save_contract');

        $(this).closest('.article').find('.the_stat').prop('checked',true);

    }else {

        $(this).css('border-color','#eb547c');
        $(this).addClass('warning-error');
        disableSaveButton('#save_contract')

    }

});


$(document).on('input','.artikel_anzahl',function () {

    let userInput = parseInt($(this).val());

    if (Number.isInteger(userInput) && userInput > 0 ){

        $(this).css('border-color','#eaeaea');
        $(this).removeClass('warning-error');
        enableSaveButton('#save_contract')

    }else {

        $(this).css('border-color','#eb547c');
        $(this).addClass('warning-error');
        disableSaveButton('#save_contract')
    }
});

// On Page Ready

$(document).on('ready',function () {

    // Hide returned Articles's delete button
    $.each($('.sub_stat'), function() {

        if ($(this).val() === 'stat_zur') {
            let index = $(this).closest('.theSub').index();
            let returnedSub = $(this).closest('.article').find('.the_stat').closest('.theSub').eq(index).find('input').attr('data-subarticle_id');
            let button = $('.delete-subContr-article[data-subartc="' + returnedSub + '"]');

            if (button){
                button.attr('disabled','disabled');
                button.attr('title','Dieser Artikel kann nicht gelöscht werden, da er bereits zurückgebucht wurde.');
                let icon = `<svg class="icon" style="width: 13px; height: 13px;">
            					<use xlink:href="#icon-disabled-rez-x"></use>
            				</svg>`;
                button.find('svg').replaceWith(icon);
            }
        }
    });

    $.each($('.art_stat'), function() {

        if ($(this).val() === 'stat_zur') {

            let button = $(this).closest('.article').find('.delete-contract-article');
            if (button){
                button.attr('disabled','disabled');
                button.attr('title','Dieser Artikel kann nicht gelöscht werden, da er bereits zurückgebucht wurde.');
                let icon = `<svg class="icon" style="width: 13px; height: 13px;">
            					<use xlink:href="#icon-disabled-rez-x"></use>
            				</svg>`;
                button.find('svg').replaceWith(icon);
            }

            $(this).closest('.article').find('.artikel_anzahl').attr('readonly', true);
            $(this).closest('.article').find('.return_anzahl_view').attr('readonly', true);

        }
    });

    // scan Article
    $('#selectByScan').on('click',function () {

        $.ajax({
            url : 'includes/artikel/ajax/scanArticleForSelect.php',
            type: 'POST',
            data: {
                method : 'ScanArticle'
            },
            success: function(data) {
                openPopup(data, '500px');
            }
        })
    });

    // On load check if more subarticles can be added
    $('.artikel_id').each(function () {

        let art_Id = $(this).val();

        let start_date;

        //TODO is this check still needed?

        if (localStorage.getItem("ArticleStartDate") === null || isNaN(localStorage.getItem("ArticleStartDate").charAt(0))) {

            start_date = $.datepicker.formatDate('d MM yy', new Date());
            // localStorage.setItem("ArticleStartDate",start_date)
        }else{

            start_date = localStorage.getItem("ArticleStartDate");
        }

        let end_date;

        if (localStorage.getItem("ArticleEndDate") === null || isNaN(localStorage.getItem("ArticleEndDate").charAt(0))) {

            end_date = $.datepicker.formatDate('d MM yy', new Date());
            // localStorage.setItem("ArticleEndDate",end_date)
        }else{

            end_date = localStorage.getItem("ArticleEndDate");
        }

        checkStockLevel (start_date, end_date, art_Id);
    });
});




function checkStockLevel (start_date, end_date, art_Id) {

    // rc: not used

    $.ajax({
        url: 'includes/vertraege/ajax/ajaxContract.php',
        type: 'POST',
        async: false,
        dataType: 'JSON',
        data: {
            method: 'checkStockLevel',
            id: art_Id,
            start_date: start_date,
            end_date: end_date,

        },
        success: function(data) {

            let disable_button = !data;

            let button = $('#contractArticle_'+art_Id).find('.add-subs-button');

            // false if article is deleted
            if(data)
            {
                // the user can add up to max articles
                let nowmaxEl = button.closest('tbody').find('.td_checks div').length;
                
                let maxEl = parseInt(data.max_arts);
                
                let left = maxEl - nowmaxEl;

                disable_button = left <= 0;
            }
            

            if(disable_button){
                
                button.attr('disabled','disabled');
                button.text('Keine weiteren Subartikel verfügbar');
            }
        }
    });

}

$(document).on('click', '#beendenButton',function (e) {
    e.preventDefault();
    
    if($(this).hasClass('disabled')) {
        return false;
    }

    let html = `<div>
					</div>
					<div class="popup-content">
					
					    <div style = "display: flex;align-items: center;justify-content: center;margin-top: 20px;">
					        <input type="checkbox"  name="articleActive" id="ArchiveCheckbox" value="1" >
                            <label for="ArchiveCheckbox" class="popup-left hour-checkbox">Archivieren</label>
                        </div>
				        
   						<div class="button submit-button archive-reservierung-yes" style="display: flex; align-items: center; justify-content: center; margin: 20px 0px 30px 136px; width: 185px;" data-id="">RESERVIERUNG BEENDEN</div>
    					
					</div>`;

    openPopup(html, '500px');

    $(document).on('click','.archive-reservierung-yes',function () {

        if($(this).closest('.popup-content').find('#ArchiveCheckbox')[0].checked) {

            window.location.href = $('#archive_url').val();

        } else {
            window.location.href = $('#beenden_url').val();
        }
    });
});

// export section
window.checkStockLevel = checkStockLevel;