
// checks whether the dates are in dd.mm.yyyy format and if the end date is after the start date
// returns true/false
function check_period(start_date, end_date) {
	// 10 for dd.mm.yyyy
	if(start_date.length != 10 || end_date.length != 10) {
		return false;
	}

	// make sure that the end date is after the start date
	check_start_date =
		new Date(start_date.substr(6, 4), start_date.substr(3, 2) - 1, start_date.substr(0, 2), 0, 0, 0, 0);

    check_end_date =
		new Date(end_date.substr(6, 4), end_date.substr(3, 2) - 1, end_date.substr(0, 2), 0, 0, 0, 0);

    if(check_end_date < check_start_date) {
      return false;
    }

	return true;
}

// converts a numer that has . for tausend separator and , for decimal separator to float
function convert_number(number) {
    return parseFloat(number.replace('.',"").replace(',',"."));
}

//Baut des XML-Ajax Request
function createXMLHttpRequest()
{
   var ua;
   if(window.XMLHttpRequest) {
   try {
   ua = new XMLHttpRequest();
   } catch(e) {
   ua = false;
   }
   } else if(window.ActiveXObject) {
   try {
   ua = new ActiveXObject("Microsoft.XMLHTTP");
   } catch(e) {
   ua = false;
   }
   }
   return ua;
}

//Pr?ft das Datumsformat
function check_date_format(date){
	if(date.match(/\d\d\.\d\d\.\d\d\d\d/) != date)
	{
		return false;
	}
	else
	{
		return true;
	}
}
//Pr?ft das Uhrzeitformat
function check_time_format(time){
	if((time.match(/\d\:\d\d/) != time) && (time.match(/\d\d\:\d\d/) != time))
	{
		return false;
	}
	else
	{
		return true;
	}
}
//Wandelt eine Zahl zb: 0900 in 09:00 um
function number_to_time(id){
	var value = document.getElementById(id).value;
	var hour = value[0]+value[1];
	if(value[2] == ':')
	{
		var min  = value[3]+value[4];
	}
	else
	{
		var min  = value[2]+value[3];
	}
	document.getElementById(id).value = hour+':'+min;
	set_raport_minuts();
}

function number_format(number, decimals, dec_point, thousands_sep) {

	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
		s = '',
		toFixedFix = function (n, prec) {
			var k = Math.pow(10, prec);
			return '' + Math.round(n * k) / k;        };
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);    }
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || '';
		s[1] += new Array(prec - s[1].length + 1).join('0');
	}    return s.join(dec);
}

/*
Author: Robert Hashemian
http://www.hashemian.com/
*/
function format_number(num, decpoint, sep) {
  // check for missing parameters and use defaults if so
  if (arguments.length == 2) {
    sep = ",";
  }
  if (arguments.length == 1) {
    sep = ",";
    decpoint = ".";
  }


  // need a string for operations
  num = num.toString();
  // separate the whole number and the fraction if possible
  a = num.split(decpoint);
  x = a[0]; // decimal
  y = a[1]; // fraction
  z = "";

// if the number is negative, it doesn't work right, so make it positive for now
  var is_negative = false;
  if (x[0] == "-") {
	is_negative = true;
	x = x.substr(1);
  }


  if (typeof(x) != "undefined") {
    // reverse the digits. regexp works from left to right.
    for (i=x.length-1;i>=0;i--)
      z += x.charAt(i);
    // add seperators. but undo the trailing one, if there
    z = z.replace(/(\d{3})/g, "$1" + sep);
    if (z.slice(-sep.length) == sep)
      z = z.slice(0, -sep.length);
    x = "";
    // reverse again to get back the number
    for (i=z.length-1;i>=0;i--)
      x += z.charAt(i);
    // add the fraction back in, if it was there
    if (typeof(y) != "undefined" && y.length > 0)
      x += decpoint + y;
	// make the number negative again, if it was
	if (is_negative)
	  x = "-" + x;
  }
  return x;
}

function get_send_email_parameters(customer_address_id, pdf_type) {
    // idcontract and contract_type is a global variable :(
    post_parameters = {}
    post_parameters.customer_address_id = customer_address_id;
    post_parameters.contract_type =
        (typeof(pdf_type) != "undefined" && pdf_type.indexOf("reminder") == 0) ? 6 /* reminder */ : contract_type;
    post_parameters.idcontract = idcontract;
    post_parameters.pdf_type = pdf_type;

    return post_parameters;
}

function handle_send_email_response(data) {
	// location.reload();
    alert(unescape(data.message));
}

function send_email(customer_address_id, pdf_type){
    post_parameters = get_send_email_parameters(customer_address_id, pdf_type);

    $.post("includes/vertraege/ajax/send_email.php",
        post_parameters,
        function(data) {
            handle_send_email_response(data);
        },
        "json"
    );
}

function time_validation(time_value){
	var valid =	(time_value.search(/^\d{2}:\d{2}$/) != -1) &&
			(time_value.substr(0,2) >= 0 && time_value.substr(0,2) <= 23) &&
			(time_value.substr(3,2) >= 0 && time_value.substr(3,2) <= 59);

	if(!valid){
		time_value = '23:59';
	}

	return time_value;
}

// in order to consider newly inserted (not yet saved) articles generate an array with their count
function get_info_about_newly_inserted_articles(exclude_article_position) {
	newly_inserted_articles = new Array();

	$('.artikel').each(function() {
		if($(this).css('display') === "block") {
			var $this = $(this);

			// get the position within the article list
			article_position = $this.find('.artikel_position').val();

			// get the initial article count - the count that was already saved in the contract
			// if this is a newly inserted article then this count is 0
			original_count = $("#orig_count_" + article_position).html();

			if(original_count === "0") {
				// get the article id
				article_id = $("#artikel_id_" + article_position).val();

				// get the article count
				article_count = $this.find('.artikel_anzahl').val();

				// get the rental period
				from = $this.find('.artikel_von_datum_c').val();
				to = $this.find('.artikel_bis_datum_c').val();

				if(exclude_article_position !== article_position) {
					newly_inserted_articles.push({id: article_id, count: article_count, from: from, to: to});
				}
			}
		}
	});

	return newly_inserted_articles;
}
  
  function add_new_pic() {

		$("#loading")
		.ajaxStart(function(){
			$(this).show();
		})
		.ajaxComplete(function(){
			$(this).hide();
		});

    var options = {
        beforeSubmit:  function() {},
        success:       function(data) {
          if (data.status == 'error') {
             alert(unescape(data.error));
          }
          if (data.status == 'ok') {
            l = art_pic.length;
            if (l>0) {
              last_row_id = '#pic_'+art_pic[l-1].replace('.','_');
            } else {
              last_row_id = '#imageUploadTrigger';
            }
            $(last_row_id).after(data.li);
            art_pic.push(data.bild);
          }
        },
        url:       'includes/artikel/ajax/upload_pic.php',  // your upload script
        dataType:  'json'
      };

    $('#upload_pic_form').ajaxSubmit(options);

  	closePopup();

}

	//checks if the character entered in textbox is a NUMBER or COMMA
	function isNumberKey(evt)
	{
	  var charCode = (evt.which) ? evt.which : event.keyCode;
	  if (charCode != 44 && charCode > 31 && (charCode < 48 || charCode > 57))
		 return false;

	  return true;
	}

	
function del_artikel(id,tab) {

	var article_name_url = $('#'+tab+'_'+id).find('.artikel_title').find('a').html();
	var article_name_no_url = $('#'+tab+'_'+id).find('.artikel_title').find('h2').html();
	if (typeof(article_name_url) != 'undefined') {
		var article_name = article_name_url;
	} else {
		var article_name = article_name_no_url;
	}

	var delete_data = '<p>' + qvapp.i18n.t('remove_article_from_contract_question') + '</p>';
		delete_data+= '<br><p><b>'+article_name+'</b></p><br>';
		delete_data+= '<button class="button_green delete-yes" data-id="'+id+'" data-tab="'+tab+'">' + qvapp.i18n.t('remove_article_from_contract_remove') + '</button>';
		delete_data+= '<button class="button_green delete-no" style="float: right">' + qvapp.i18n.t('remove_article_from_contract_cancel') + '</button>';


    document.getElementById('short_main_div').style.display = 'block';
    document.getElementById('short_center').style.display ="none";
    document.getElementById('short_main_div_inhalt').innerHTML = delete_data;

	$('#short_center')
	    // .css({"display": "none", "width": "180px", "height": "30px", "margin-left": "-200px", "margin-top": "-90px"})
        .show(300);
}

function confirm_del_artikel(id,tab){

	var article_name = $('#'+tab+'_'+id).find('.artikel_title').find('a').html();

    var el = "#" + tab + "_" + id;
    $(el).remove();

	var delete_message = '<p>' + qvapp.i18n.t('remove_article_from_contract_confirmation') + '</p>';
		delete_message+= '<br><p><b>'+article_name+'</b></p>';
	$("#short_main_div_inhalt").html(delete_message);
}

function del_pic(bild) {
    if (bild != '_dummy.svg') {
      $("#pic_"+bild.replace('.','_')).remove();
      post_parameters = {};
      post_parameters.idmaterial = idmaterial;
      post_parameters.bild = bild;
      $.post("includes/artikel/ajax/del_pic.php",post_parameters,function(data) {

      },"json");
      art_pic = $.grep(art_pic, function(value) {
        return bild != value;
      });
    }
}

function getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
}

function dateDMYtoMDY(dateDMY, separator) {
	dateDMY = dateDMY.split(separator);
	dateMDY = dateDMY[1] + separator + dateDMY[0] + separator + dateDMY[2];

	return dateMDY;
}

function dateMDYtoDMY(dateMDY, separator) {
	dateMDY = dateMDY.split(separator);
	dateDMY = dateMDY[1] + separator + dateMDY[0] + separator + dateMDY[2];

	return dateDMY;
}

function dateDMYtoYMD(dateDMY, separator) {
	dateDMY = dateDMY.split(separator);
	dateYMD = dateDMY[2] + separator + dateDMY[1] + separator + dateDMY[0];

	return dateYMD;
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function validatePopupDates($this){

	var start = "";
	var end = "";
	var valid = true;
	var fromArticle = false;

	if ($this.hasClass('artikel_von_datum_c')) {	// deal with contract article list dates   .artikel_von_datum_c, .artikel_bis_datum_c
		start = $this;
		end = $this.parents().find('.artikel_bis_datum_c');
		fromArticle = true;
	} else if ($this.hasClass('artikel_bis_datum_c')){
		start = $this.parents().find('.artikel_von_datum_c');
		end = $this;
		fromArticle = true;
	}
	else if ($this.attr('id') == 'insert_scan_from_date') {	// deal with scan article popup dates 	#insert_scan_from_date, #insert_scan_to_date
		start = $this;
		end = $this.parents().find('#insert_scan_to_date');
	} else if ($this.attr('id') == 'insert_scan_to_date'){
		start = $this.parents().find('#insert_scan_from_date');
		end = $this;
	}
	else if ($this.attr('id') == 'datepickerInsertFromDate') {	// deal with add article popup dates 	#datepickerInsertFromDate, #datepickerInsertToDate
		start = $this;
		end = $this.parents().find('#datepickerInsertToDate');
	} else if ($this.attr('id') == 'datepickerInsertToDate'){
		start = $this.parents().find('#datepickerInsertFromDate');
		end = $this;
	}

	valid = validate_start_end_dates(start, end);
	if (valid===false && fromArticle === false) {
		$("#contractArticleTable").hide();
	} else {
		$("#contractArticleTable").show();
	}

	return valid;
}

function validateDatesForAllArticlesInContract()
{
	var all_valid = true;
    var validates = false;

	$('.artikel').each(function(i,e){
		var position = $(this).find('.artikel_position').val();
        //console.log('validate position: '+position);

        if($(this).find('select.art_stat').length)
        {
            //console.log('it is art_stat');

    		var start = $(this).find('#artikel_von_datum_' + position); // $('.artikel_von_datum_c', e);
            //console.log('validateDates start: '+start.attr('id'));
    		var stops = $(this).find('#artikel_bis_datum_' + position); // $('.artikel_bis_datum_c', e);
            //console.log('validateDates stops: '+end.attr('id'));

            validates = validate_start_end_dates(start, stops);
            //console.log('date validates: '+validates);
            if(validates === false){all_valid = false;}
        }
        if($(this).find('select.sub_stat').length)
        {
            //console.log('it is sub_stat');
            $(this).find('.artikel_von_datum_c').each(function(idx){
                var start = $(this);
                var stops = $(this).closest('tr').find('.artikel_bis_datum_c').eq(idx);

                validates = validate_start_end_dates(start, stops);
                //console.log('date validates: '+validates);
                if(validates === false){all_valid = false;}
            });
        }
	});

    //console.log('all_valid: '+all_valid);
	return all_valid;
}

function validateRentedReturnedAmmount() {

	var valid = true;
	$('.artikel').each(function (i, e) {
		var elem     = $(this);
		var position = elem.find('.artikel_position').val();

		var articleCount    = elem.find('#artikel_anzahl_' + position);
		var articleReturned = elem.find('#return_anzahl_artikel_' + position); // $('.artikel_bis_datum_c', e);

        var cntArt = parseInt(articleCount.val());
        var cntRet = parseInt(articleReturned.val());
        //console.log('cntArt: '+cntArt+'\n'+'cntRet: '+cntRet);

		if (cntRet > cntArt) {

			valid = false && valid;
		} else {

			valid = true && valid;
		}
	});

    //console.log('validateRentedReturnedAmmount valid : '+valid);
	return valid;
}

function validate_start_end_dates(start, end)
{
	var valid = true;

    //console.log('\n'+'validate_dates > start: '+start.toSource()+'\n'+'validate_dates > end: '+end.toSource());
	var startVal = convertPickerDateToTimestamp(start);
	var endVal = convertPickerDateToTimestamp(end);
    //console.log('\n'+'validate startVal: '+startVal+'\n'+'validate endVal: '+endVal);

	// we got start & end string dates. now let's turn them into comparable dates
	if (startVal != "" && endVal != "") {
		if (startVal <= endVal) {
			start.parent().find('.datetimepicker-output').removeClass('red');
			end.parent().find('.datetimepicker-output').removeClass('red');
		} else {
			start.parent().find('.datetimepicker-output').addClass('red');
			end.parent().find('.datetimepicker-output').addClass('red');
			valid = false;
		}
	} else {
		valid = false;
	}

	return valid;
}

function validate_start_end_dates_for_cookie(start, end) {

	var valid = true;

	// we got start & end string dates. now let's turn them into comparable dates
	if (start != "" && end != "") {
		var start_date = dateDMYtoYMD(start, '.');
		var end_date = dateDMYtoYMD(end, '.');

		start_date = replaceAll(start_date, '.', '-');
		end_date = replaceAll(end_date, '.', '-');

		if ((new Date(start_date) == "Invalid Date") || (new Date(end_date) == "Invalid Date") || (new Date(start_date) > new Date(end_date))) {
			valid = false;
		}
	}

	return valid;
}

function replaceAll(str, find, replace) {
  return str.split(find).join(replace);
}

function objToString (obj) {
    var str = '';
    for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
            str += p + '::' + obj[p] + '\n';
        }
    }
    return str;
}

function openPopup(html, width) {
	var popup = $('#popup');

	popup.html(html);

	// open modal with custom settings
	popup.modal({
		clickClose: false
	});

	// on leaving, ask for confirmation if form is not saved
    popup
    .off("click.modal", 'a[rel~="modal:close"]')
    .on("click.modal", 'a[rel~="modal:close"]', function(e) {
		if ($('.save-button', popup).hasClass('submit-button') && !$('.save-button', popup).hasClass('submit-button-disabled')
			&& !$("#popup").find('.in-contract-popup').html()) {

	        if (confirm('Möchten Sie das Fenster schliessen?')) {
	            // close and disable save button
				$('.save-button', popup).removeClass('submit-button');
				$('.save-button', popup).addClass('submit-button-disabled');
	        } else {
	            e.preventDefault();
	            return false;
	        }
	    }
    });
	popup.width(width);

	return popup;
}

function openPopupOverPopup(html, width, callback = false) {
	$('#popup1').html(html);
	$('#popup1').width(width).css('overflow', '');

	// open modal with custom settings
	$('#popup1').modal({
		closeExisting: false
	});

	$("#popup1")
	.off("click.modal")
	.on("click.modal", 'a[rel~="modal:close"]', function(e) {
		if(callback) {
			callback();
		}
	});

    return false;
}

function closePopup() {
	$('.close-modal').click();
    return false;
}

/*
* Get current date in d.m.Y format
* 				   or d-m-Y
*				   or Y-m-d
*				   or Y.m.d
*                  or Y-m-d H:i
*                  H:i
*/
function getCurrentDate(format) {
	var today = new Date();
	var dd = today.getDate();
	var mm = today.getMonth()+1; //January is 0!
	var yyyy = today.getFullYear();

	var hr = today.getHours().toString().padStart(2, '0');;
	var mn = today.getMinutes().toString().padStart(2, '0');;

	if(dd<10) {
	    dd='0'+dd
	}

	if(mm<10) {
	    mm='0'+mm
	}

	switch (format) {
		case 'd.m.Y': today = dd +'.'+ mm +'.'+ yyyy; break;
		case 'd-m-Y': today = dd +'-'+ mm +'-'+ yyyy; break;
		case 'Y.m.d': today = yyyy +'.'+ mm +'.'+ dd; break;
		case 'Y-m-d': today = yyyy +'-'+ mm +'-'+ dd; break;
		case 'Y-m-d H:i': today = yyyy +'-'+ mm +'-'+ dd +' '+ hr +':'+ mn; break;
		case 'H:i': today = hr +':'+ mn; break;
	}

	return today;
}

/*
* Convert string date to d.m.Y format
* 				   	  or d-m-Y
*				   	  or Y-m-d
*				   	  or Y.m.d
*/
function convertStringDate(stringDate, format) {
	var today = new Date(stringDate);
	var dd = today.getDate();
	var mm = today.getMonth()+1; //January is 0!
	var yyyy = today.getFullYear();

	var hr = today.getHours().toString().padStart(2, '0');
	var mn = today.getMinutes().toString().padStart(2, '0');

	if(dd<10) {
	    dd='0'+dd
	}

	if(mm<10) {
	    mm='0'+mm
	}

	switch (format) {
		case 'd.m.Y': today = dd +'.'+ mm +'.'+ yyyy; break;
		case 'd-m-Y': today = dd +'-'+ mm +'-'+ yyyy; break;
		case 'Y.m.d': today = yyyy +'.'+ mm +'.'+ dd; break;
		case 'Y-m-d': today = yyyy +'-'+ mm +'-'+ dd; break;
		case 'Y-m-d H:i': today = yyyy +'-'+ mm +'-'+ dd +' '+ hr +':'+ mn; break;
		case 'H:i': today = hr +':'+ mn; break;
	}

	return today;
}

/*
* Converts picker date to timestamp
*/
function convertPickerDateToTimestamp(pickerDate) {
	var dateTimestamp = '';

    if(pickerDate)
    {
    	if (typeof pickerDate.pickadate('picker') === 'object') {
    		dateTimestamp = pickerDate.pickadate('picker').get('select').pick;
    	} else {
    		dateTimestamp = pickerDate.val() || 0;
    	}
    }

	return dateTimestamp;
}

$(document).ready(function() {
    /*
	* NAVIGAION
    */
    // toggle navigation menus
    $('.main-nav-items li').click(function() {
    	$(this).toggleClass('main-nav-activated');
    	$('#sub_nav_'+$(this).attr('nav')).toggle();
    });

	$('#scanArticleForHandout').live("click", function() {
		var elem       = $(this);
		var contractId = elem.attr('data-contract-id');

		scanArticleForHandout(contractId);

		return false;
	});

	$("#kunden").live('change', function() {
       var kunden = $("#kunden").html();
       if(kunden.length > 0) {
			title = "<span class='client_name'>" + kunden + "</span>";
            $("#header_value").html(" f&uuml;r " + title);
       }
    });

    // if close button is clicked
    $('.window .close').click(function (e) {
        //Cancel the link behavior
        e.preventDefault();
        $('#mask, .window').hide();
    });

    // if mask is clicked
    $('#mask').click(function () {
        $(this).hide();
        $('.window').hide();
    });

   	// normal article
	$(".artikel_von_uhr_c, .artikel_bis_uhr_c").mask("99:99", {placeholder:"0"});
	$(".artikel_von_datum_c, .artikel_bis_datum_c").mask("99.99.9999", {placeholder:"0"});

	$('.return_anzahl').on('change', function (e) {

		var elem = $(this);
		var row  = elem.closest('tr');

		var articlesCount = row.find('.artikel_anzahl');

		if (elem.val() > articlesCount.val()) {

			elem.addClass('red');
		} else {

			elem.removeClass('red');
		}
	});

    function check_if_article_is_available($this) {
		var $parent_article = $this.parents('tbody.artikel');

		var block_days_before = parseInt($parent_article.find(".article_days_block_before").val());
		var block_days_after = parseInt($parent_article.find(".article_days_block_after").val());

		// check if this is article or subarticle
		var has_subarticles = $parent_article.find('.has_subarticles').val();

		var contractid = typeof(idcontract) == 'undefined' ? 0 : idcontract;

		if(has_subarticles == 1) {
			// check if subarticle is available

			// get the document wrapper, since the check for subarticle is triggered only by interval change
			var $dates_wrapper = $this.parents('.dates_wrapper');

			// get interval
			var interval = get_interval_from_date_wrapper($dates_wrapper, true);
			let start_date = interval.start_date;
			let end_date = interval.end_date;
			let subarticle_id = $dates_wrapper.data('subarticle_id');
			
			if(interval.is_valid) {
				checkIfSubarticleIsAvailable(contractid, start_date, end_date, 
					subarticle_id, block_days_before, block_days_after, 

					function(is_subarticle_available) {
						mark_subarticle_dates_as_not_available($dates_wrapper, is_subarticle_available);
					}
				);
			}

			return;
			
		} else {
			// check if article is available

			// get the document wrapper, since the check for subarticle is triggered only by interval change
			var $dates_wrapper = $parent_article.find('.dates_wrapper');

			// get interval
			var interval = get_interval_from_date_wrapper($dates_wrapper, true);
			let start_date = interval.start_date;
			let end_date = interval.end_date;
			let article_id = $parent_article.find('.artikel_id').val();
			let article_count = $parent_article.find('.artikel_anzahl').val();
			
			if(interval.is_valid) {
				checkIfArticleIsAvailable(contractid, start_date, end_date, 
					article_id, article_count, block_days_before, block_days_after, 

					function(is_article_available) {
						mark_article_as_not_available($parent_article, is_article_available);
					}
				);
			}
		}
		
		return;
    }

    $(function(){
		var lazy_change_count_or_date = _.debounce(function(e) {
			if(!quickverleih.storage.disable_availability_check) {
				check_if_article_is_available($(this));
			}
		}, 300);

    	$(".artikel_anzahl, .artikel_von_datum_c, .artikel_bis_datum_c").live('keyup change', lazy_change_count_or_date);
    });

	function mark_subarticle_dates_as_not_available($dates_wrapper, is_available) {
		if(is_available) {
			$dates_wrapper.removeClass('not_available');
		} else {
			$dates_wrapper.addClass('not_available');
		}
	}

	function mark_article_as_not_available($article, is_available) {
		if(is_available) {
			$article.removeClass('not_available');
		} else {
			$article.addClass('not_available');
		}
	}

	// on the login page focus on the user name
    $("#i_bname").focus();

	$(".save_contact_first").live("click", function() {
		alert("Bitte speichern Sie den Vertrag zuerst.");

		return false;
	});

	// if editing contracts
	if($(".edit_mode").length > 0) {
		$("#kunden").change();

		// overview();
    setTimeout(function() { $("#register_feld4").click(); }, 100);
	}

	// do redirects
	var $redirect = $("#do_redirect");
	if($redirect.length > 0) {
		url = $redirect.text();
		window.location = url;
	}

	$("#shipment").keyup(function() {
		total = parseFloat($("#ov_total_field").val());

		// calculate the total sum
		shipment_val = parseFloat($("#shipment").val().replace(/\./gi, "").replace(",", "."));
		total_sum = total + shipment_val;
        total_sum_display = format_number(total_sum.toFixed(2).toString().replace(/\./gi, ","), ",", ".");
        $("#ov_sum_total").html(total_sum_display+" &euro;");
		$("#ov_sum_total_field").val(total_sum);

	});

	$("#contract_details_form").on("submit", function() {
		if($(".rental-article.not_available").length > 0 || 
			$(".theSub .not_available").length > 0 ||
			$(".dates_wrapper.not_available").length > 0) {

			alert(qvapp.i18n.t("vertrage_not_available"));

			return false;
		}

		if($(".article.not_available_duration").length > 0) {
			alert(qvapp.i18n.t("vertrage_not_available"));

			return false;
		}

		if(validateDatesForAllArticlesInContract() === false) {
			alert(qvapp.i18n.t("vertrage_not_available"));

			return false;
		}

		if (validateRentedReturnedAmmount() === false) {
			alert(qvapp.i18n.t("vertrage_not_available"));

			return false;
		}


		return true;
	});

	/* Scan articles for handout
	*	! this only marks articles as handed out, not subarticles !
	*	  this is just a flag used for the new contract status
	*/
	$('#submit_scan_handout_articles').live("click", function() {
		var articles_gnr = $("#insert_scan_handout_articles").val();
		articles_gnr = articles_gnr.split(/[\s,]+/);	//split text and lose unwanted characters

	//	var return_qty = '';
	//	var return_id_sub = '';
		var article_number = '';
		var error = false;
		var error_missing_articles = "";

		$('#error_scan_handout_articles_numbers').html();	// reset error message

		//take all inserted article numbers and mark handout 1 if article is found in contract
		$.each(articles_gnr, function(index, value) {
            if(value == '') {
                return;
            }

            // we only use the base article gnr
            var article_gnr = value.split('-');

			// first we check if the scanned number comes from an article
			var handout_input =$('.'+article_gnr[0]);
			if ( handout_input.val() != undefined ) {	// check if handout input exists for this article
				handout_input.val('1').keyup();
				handout_input.parent().find('.article-handed-out-tag').show();

                // .................................
                // changes the article select option
                // .................................
                handout_input.closest('tr').find('.art_stat, .sub_stat').val(1);
                // .................................
			} else { // if there is no match, than this number is not in contract
				error_missing_articles += ' '+article_gnr[0]+' ';
				error = true;
			}
		});

		//close popup #short_main_div
		if (error == false) {
			$(this).parent().parent().parent().css('display','none');
			closePopup();
		} else {
			$('#error_scan_handout_articles_numbers').html(error_missing_articles);
			$('#error_scan_handout_articles').show();
		}

	});

	$(".clientRestriction").click(function() {
		var self = $(this);
		var addRemove = 0;//remove default

		 if (self.is(":checked")){//if checked
			  self.attr('checked', 'checked');
			  addRemove = 1;//add

		  }else{
			  self.removeAttr("checked");
			  addRemove = 0;//remove
		  }

		 var dataToSend = {
				 id_category: $(this).attr("data-idcategory"),
				 id_client: $(this).attr("data-idclient"),
				 operation: addRemove
		 };
			$.ajax({
			  type: "POST",
			  url: "includes/kunden/ajax/add_remove_category.php",
			  data: dataToSend,
			})
	});

	 $("#add_firma_form").on('submit',function(e) {
		var show_message=0;
		 $(".must_have").each(function(e){
			    if($(this).val()=='')
			    {
			    	show_message = 1;
			    }
			 }
		 );
		 if (show_message==1){
			 alert("Es muss ein Firmenname, Standortbezeichnung, Inhaber, eMail!");
			 return false;
		 }
		 return true;
	 });

	// this doesn't work on new contract because the checkbox is added by ajax (after the js is loaded)
	// so the "on change" event is not bound
	// to avoid this we need a container that is there before the js -> kunden_auswahl
	// $("#disable_availability_checkbox").on("change", function() {
	$("#kunden_auswahl").on("change", "#disable_availability_checkbox", function() {
		if ($("#disable_availability_checkbox").is(':checked') == true) {
			$("#disable_availability").val(1);
		} else {
			$("#disable_availability").val(0);
		}

		// trigger article availability check
		$(".artikel_anzahl").trigger("keyup");
	});

	$("#member").on("change", function() {
		if ($("#member").is(':checked') == true) {
			$("#member_no_vat").prop('checked', true);
		} else {
			$("#member_no_vat").prop('checked', false);
		}
	});

	$("#has_subarticles").on("change", function() {
		if ($("#has_subarticles").is(':checked') == true) {
			$("#register_feld5").removeClass('hidden');
		} else {
			$("#register_feld5").addClass('hidden');
		}
	});

	// delete articles from contract
	$(".delete-yes").live("click", function() {
		var id = $(this).data('id');
		var tab = $(this).data('tab');

		// prepare log variables
		var id_article = $('#'+tab+'_id_'+id).val();
		var id_subarticle = $('#subarticle_ids_'+id).val();
		var action = "delete article from contract";

		confirm_del_artikel(id,tab);

		//log article delete
		saveLog(id_article, id_subarticle, action);
	});
	// don't delete articles from contract
	$(".delete-no").live("click", function() {
		$("#short_main_div").fadeOut("fast");
	});


	/* vvv ENABLE SAVE BUTTONS ON FORM CHANGE */
	$(
		'#contract_details_form'
		+','+
		'#settingsOrganisationForm'
		+','+
		'#settingsShopForm'
		+','+
		'#settingsAnfrageForm'
		+','+
		'#settingsReservationForm'
		+','+
		'#settingsSystemForm'
		+','+
		'#settingsMahnungForm'
		+','+
		'#settingsForm'

	 ).on("keyup change",function() {
		var buttonId = $(this).find('.save-button').attr('id');
		if($('#contract_details_form').length)
		{
			if($('#contractCustomerName').length && $('#contractCustomerName').html().length)
			{
				enableSaveButton('#'+buttonId);
			}
		}
		else
		{
			enableSaveButton('#'+buttonId);
		}
	});

	// this method is used for save buttons loaded by ajax
	$(document).on("keyup change", '#articleForm, #customerForm', function() {
		var buttonId = $(this).find('.save-button').attr('id');

		// check for errors
		var errors = false;
		$.each($(this)[0], function(index, value) {
			//console.log(value);
			if ($(value).hasClass('red') || $(value).hasClass('field_error')) {
				errors = true;
				//console.log(value);
			}
		});

		if (errors === false) {
			enableSaveButton('#'+buttonId);
		} else {
			disableSaveButton('#'+buttonId);
		}

	});
	/* ^^^ ENABLE SAVE BUTTONS ON FORM CHANGE */

	$('.changeResStatus, .act-deact-contract').on('click',function () {

		let link = $(this).attr('data-link');
		let convertCode = $(this).attr('data-convert');

		saveConfirmationPopup(link, convertCode);
	});
});

function saveConfirmationPopup(link, convertCode) {

    let text;

    switch (parseInt(convertCode)) {
        case 1:
            text = "Möchten Sie die Reservierung aktivieren?";
            break;
        case 2:
            text = "Möchten Sie die Reservierung in eine Anfrage umwandeln?";
            break;
        case 3:
            text = "Möchten Sie die Anfrage in eine Reservierung umwandeln?";
            break;
        case 4:
            text = "Möchten Sie die Reservierung stornieren?";
            break;
    }

	let data = `<div class="dash-block-header">
    					<div class="message-header-title text-uppercase">${text}</div>
					</div>
					<div class="popup-content">
   						<div class="button delete-button confirm-leave" data-link="${link}">Ja</div>
    					<a href="#close-modal" rel="modal:close">
        					<div class="button white-button remain" style="float: right">ABBRECHEN</div>
    					</a>
					</div>`;

	openPopupOverPopup(data, '500px');
}

$(document).on('click','.confirm-leave',function () {
	let link = $(this).attr('data-link');
	window.onbeforeunload = null;
	window.location.replace(link);
});

function enableSaveButton(buttonId) {

	if ($(buttonId).hasClass('submit-button-disabled') && !$('.warning-error')[0] ) {

		$(buttonId)
			.removeClass('submit-button-disabled')
			.addClass('submit-button')
			.removeAttr('disabled');

	}

}

function disableSaveButton(buttonId) {

	if ($(buttonId).hasClass('submit-button')) {

		$(buttonId)
			.removeClass('submit-button')
			.addClass('submit-button-disabled')
			.attr('disabled','disabled');

	}

}

function charsInArray(text, acceptable) {

	var allowed = true;

    // Loop through each character in the string
    var length = text.length;
    for (var i = 0; i < length; i++) {
        // This if condition is fulfilled if the character
        // is not in the array of acceptable characters
        if (jQuery.inArray(text[i], acceptable) < 0)
            allowed = false;
    }

    return allowed;
}

disable_article_types = false;

// .............................................................................
// triggers popup for QR code from the dashboard top bar button
// .............................................................................
function qr__search(qr_srch)
{
	if(qr_srch == '' || qr_srch === undefined || qr_srch === null){ return false; }

	var html = ''
				+ '<div class="qr_data">'
					+ '<div class="col-max-md-one-full qr_wrap">'

						+ '<div id="qr_html" style="width:100%; display:block;">'
						+ '<div style="margin:25px auto; padding:15px; text-align:center; border-top:1px solid #DDD; border-bottom:1px solid #DDD;">... loading data ...</div>'
						+ '</div>'

					+ '</div>'
				+ '</div>'
			;

	openPopup(html, '1200px');

	$('.qr_data').parent().addClass('rounded');

	var dataHtml = $('.qr_data').find('#qr_html');

  	$.ajax({
		url: 'includes/vertraege/ajax/ContractArticlesQR.php',
		type: 'POST',
		data: { 'qr_srch' : qr_srch },
		success: function(data) {
			dataHtml.html(data);
			$('#qrCheck').html(qr_srch);

            var dataTablesOptions = setDataTablesOptions($('#QRTableWrapper').find('.data-table-block'));
            var data_table = $('#qr_fill').DataTable( dataTablesOptions );

            // .................
            // sorting by column
            // .................
            data_table.column('4').order('desc').draw();

            $('.reordered').each(function(index, eleme){

                var show_date = $(this).attr('data-show');
                //console.log('show_date: '+show_date);
                var used_date = $(this).find('span').html();
                //console.log('used_date: '+used_date);
                $(this).find('span').html(show_date);
                $(this).attr('data-show', used_date);
            });
            // .................
		}
	});
}
function qr_doValue()
{
	var qr_srch = $('.qr_pops input[type="search"]').val();
	//console.log('qr_srch: '+qr_srch);

	if(qr_srch != '')
	{
		closePopup();
		qr__search(qr_srch);
	}
}
function qr_codeGet()
{
	if($('#qr_pops').length)
	$('#qr_pops').on('click', function(e){
		e.preventDefault();

		var html = ''
					+ '<div class="qr_pops">'
						+ '<div class="col-max-md-one-full qr_wrap">'

							+ '<div class="qr_col1 qr_icon">'
								+ '<svg class="icon">'
									+ '<use xlink:href="#icon-qr-code"></use>'
								+ '</svg>'
								+ '<center>Artikel jetzt scannen</center>'
							+ '</div>'

							+ '<div class="qr_col1 qr_midl">'
							+ '- oder -'
							+ '</div>'

							+ '<div class="qr_col3">'
							+ '<div class="data-table-block dash-block-container">'

									+ '<div class="data-table-header dash-block-header flex">'
										+ '<div class="data-table-name text-uppercase">Artikelnummer Eingeben</div>'
									+ '</div>'

									+ '<div class="data-table-content">'

										+ '<div class="dataTables_filter">'
											+ '<label>'
												+ '<input id="qr_area" placeholder="" type="search" />'
											+ '</label>'
											+ '<svg class="icon">'
												+ '<use xlink:href="#icon-search"></use>'
											+ '</svg>'
										+ '</div>'

										+ '<div class="button submit-button text-uppercase button-margins" id="qr_srch">Informationen Abrufen</div>'

									+ '</div>'

							+ '</div>'
							+ '</div>'

						+ '</div>'
					+ '</div>'
				;

		openPopup(html, '470px');

		$('.qr_pops').parent().addClass('rounded');

        $('#qr_area').focus();

		$('.qr_pops .submit-button').on('click', function(e){
			qr_doValue();
		});
		$(document).keypress(function(e) {
		    if(e.which == 13)
            {
                e.preventDefault();
				$('.qr_pops .submit-button').trigger('click');
            }
		});
	});
}
$(function(){
	//qr__search('AC0');
	qr_codeGet();
});
// .............................................................................



// .............................................................................
// helps on month selector at dashboard
// .............................................................................
$(function() {
	function prevNextMonthYear(delta, defaultForPrevNextMonth, isMonthValid) {
		var selectedMonth = $('#table-stats-month-data').val();
		var prevNextMonth = parseInt(selectedMonth) + delta;

		let monthYearSelectionUpdated = false;

		// if prev/next month is valid, select it
		if(isMonthValid(prevNextMonth)) {
			$('#table-stats-month-data').val(prevNextMonth);

			monthYearSelectionUpdated = true;
		} else {
			var currentYear = $('#table-stats-year-data').val();
			var prevNextYear = parseInt(currentYear) + delta;

			// if the prev/next year exists select it, as well as the default month for that year
			if($(`#table-stats-year-data option[value='${prevNextYear}']`).length > 0) {
				$('#table-stats-year-data').val(prevNextYear);
				$('#table-stats-month-data').val(defaultForPrevNextMonth);

				monthYearSelectionUpdated = true;
			}
		}

		return monthYearSelectionUpdated;
	}

	if($('#table-stats-month-data').length)
	{
		$('.month-pre').live('click', function(ev){
			ev.preventDefault();

			if(prevNextMonthYear(-1, 12, (prevNextMonth) => prevNextMonth >= 1)) {
				$('#table-stats-month-data').trigger('change');
			}
		});

		$('.month-nxt').live('click', function(ev){
			ev.preventDefault();
			
			if(prevNextMonthYear(1, 1, (prevNextMonth) => prevNextMonth <= 12)) {
				$('#table-stats-month-data').trigger('change');
			}
		});
	}
});
// .............................................................................

// .............................................................................
// adds + deletes new subarticle buttons & trigger
// .............................................................................
$(function(){
    $('.add-subs-button').live('click', function(){

		var this_AddButton = $(this);
		var $parent_tbody = this_AddButton.parents('tbody');

		var article_position = $parent_tbody.find('.artikel_position').val();

		// insert the new article
		get_next_subarticle_for_contract(this_AddButton, article_position)
		.then(function(next_subarticle) {
			var next_subarticle_id = next_subarticle.subarticle_id;
			var next_subarticle_number = next_subarticle.subarticle_number;
			var next_subarticle_is_not_available = next_subarticle.subarticle_is_not_available;
			var start_date = next_subarticle.start_date;
			var end_date = next_subarticle.end_date;

			// gets article id
			var art_Id = $parent_tbody.find('.artikel_id').val();

			var totalEl_Counts = $parent_tbody.find('.td_checks').find('.theSub').length;
			totalEl_Counts = parseInt(totalEl_Counts) + 1;
			var the_New_Row_ID = art_Id+'_'+totalEl_Counts;
			
			let artName = $parent_tbody.find('.contract_articles_link')[0].innerText;

			// changes articles counting
			var oldNum = $parent_tbody.find('.artikel_anzahl').val();
			var newNum = parseInt(oldNum)+1;
			$parent_tbody.find('.artikel_anzahl').val(newNum);

			// update the subarticle ids
			var subarticle_ids = $parent_tbody.find('.subarticle_ids').val();
			if(subarticle_ids.length > 0) {
				subarticle_ids += ";";
			}
			subarticle_ids += next_subarticle_id;

			$parent_tbody.find('.subarticle_ids').val(subarticle_ids);

			var last_td_checks = $parent_tbody.find('.td_checks');

			var not_available_class = next_subarticle_is_not_available ? 'not_available' : '';

			var last_el_theSub = ''
						+'<div class="theSub">'
						+'<input data-subarticle_id="' + next_subarticle_id + '"'
							+'type="checkbox" class="the_stat" '
							+'style="width:0; height:0;" '
							+'name="new_sub_'+the_New_Row_ID+'" '
							+'id="new_sub_'+the_New_Row_ID+'" '
						+'/>'
						+'<label for="new_sub_'+the_New_Row_ID+'" style="margin-left: 3px;">'+next_subarticle_number+'</label>'
						+'</div>'
			;
			last_td_checks.append( last_el_theSub );

			// shows the new subart stats
			var last_td_checks = $parent_tbody.find('.td_status');
			var last_el_theSub = last_td_checks.find('.theSub').last().clone();
			last_el_theSub.find('.sub_stat').removeAttr('id').removeAttr('data-stat').removeAttr('name').removeAttr('selected');
			last_el_theSub.find('.sub_stat').find('option').removeAttr("selected");
			last_el_theSub.find('.sub_stat').val('stat_res');
			last_el_theSub.attr('id', 'new_sub_'+the_New_Row_ID);
			last_td_checks.append( last_el_theSub );

			// add the subarticles dates			
			var id_for_dates = article_position + '_' + (totalEl_Counts - 1);

			var last_td_checks = $parent_tbody.find('.td_dates');
			var last_el_theSub = 
			'<div class="dates_wrapper ' + not_available_class + '" id="dates_wrapper_' + the_New_Row_ID + '" data-subarticle_id="' + next_subarticle_id + '">' +
				'<div class="td_vonDat start_date_picker datepicker_root">' +
					'<div id="artikel_von_datum_'+id_for_dates+'_wrap" class="datepicker-wrap datepicker-toggle theSub" data-pickerclass="artikel_von_datum_c" data-type="itis_sub">' +
					'</div>' +
				'</div>';

			last_el_theSub += 
				'<div class="td_bisDat end_date_picker datepicker_root">' +
					'<div id="artikel_bis_datum_'+id_for_dates+'_wrap" class="datepicker-wrap datepicker-toggle theSub" data-pickerclass="artikel_bis_datum_c" data-type="itis_sub">' +
					'</div>' +
				'</div>' +
			'</div>';
			last_td_checks.append( last_el_theSub );

			quickverleih.storage.disable_availability_check = true;

			try {
				var date_split = start_date.split("T");
				// initialize the datepickers
				datePicker.add("artikel_von_datum_"+id_for_dates+"_wrap", 
					convertStringDate(date_split[0], 'd.m.Y'), date_split[1], (ENABLE_HOURS ? "noTime-remove" : "noTime"));

				date_split = end_date.split("T");
				datePicker.add("artikel_bis_datum_"+id_for_dates+"_wrap", 
					convertStringDate(date_split[0], 'd.m.Y'), date_split[1], (ENABLE_HOURS ? "noTime-remove" : "noTime"));
			}

			finally {
				quickverleih.storage.disable_availability_check = false;
			}

			// shows the subart number
			var last_td_checks = $parent_tbody.find('.td_numb_1');
			var last_el_theSub = last_td_checks.find('.theSub').last().clone();
			last_el_theSub.find('.justNr').removeAttr('id');
			last_el_theSub.attr('id', 'new_num_'+the_New_Row_ID);
			last_td_checks.append( last_el_theSub );

			// shows the subart returns
			var last_td_checks = $parent_tbody.find('.td_numb_2');
			var last_el_theSub = last_td_checks.find('.theSub').last().clone();
			last_el_theSub.find('.return_anzahl_sub').removeAttr('id').removeAttr('value');
			last_el_theSub.attr('id', 'new_ret_'+the_New_Row_ID);
			last_td_checks.append( last_el_theSub );

			// shows r-datum field
			let last_r_date = $parent_tbody.find('.td_r-date');
			let r_date_input = `<div class="theSub return_date" id="ret_S_Dates_val_${the_New_Row_ID}"><input type="text" value="" class="return_date_input width-88 noBorder theSub date_pad" readonly /></div>`;
			last_r_date.append( r_date_input );

			var last_td_checks = $parent_tbody.find('.td_action');
			var last_el_theSub = ''
						+'<button type="button" class="reset-button x-button delete-subContr-article theSub" id="delete_sub_new_'+the_New_Row_ID+
							'" data-article="'+art_Id+'" data-subname="'+next_subarticle_number+'" data-subartc="'+next_subarticle_id+'" data-pos="'+the_New_Row_ID+
							'" data-artname="'+artName+'">'

							+'<svg class="icon" style="width:13px; height:13px;">'
								+'<use xlink:href="#icon-x"></use>'
							+'</svg>'
						+'</button>'
			;
			last_td_checks.append( last_el_theSub );

			checkStockLevel (start_date, end_date, art_Id);
		});
    });
});

function get_interval_from_date_wrapper($dates_wrapper, validate = false)
{
	var $start_date = $dates_wrapper.find(".start_date_picker > .datepicker-wrap");
	let start_date = convertPickerDateToTimestamp($start_date.find(".artikel_von_datum_c"));

	let $start_date_output = $start_date.find(".datetimepicker-output");
	let start_time = $start_date_output.val();
	if(start_time.length == 0) {
		start_time = "00:00";
	} else {
		start_time = start_time.slice(-5);
	}	

	start_date = convertStringDate(start_date, 'Y-m-d') + "T" + start_time;

	var $end_date = $dates_wrapper.find(".end_date_picker > .datepicker-wrap");
	let end_date = convertPickerDateToTimestamp($end_date.find(".artikel_bis_datum_c"));

	let $end_date_output = $end_date.find(".datetimepicker-output");
	let end_time = $end_date_output.val();
	if(end_time.length == 0) {
		end_time = "00:00";
	} else {
		end_time = end_time.slice(-5);
	}

	end_date = convertStringDate(end_date, 'Y-m-d') + "T" + end_time;

	let return_data = {
		start_date: start_date,
		end_date, end_date
	};

	// validating the interval - check if this is needed, since the pickers do not allow this case anymore
	if(validate)
	{
		if(new Date(start_date) > new Date(end_date)) {
			return_data.is_valid = false;

			$start_date_output.addClass('red');
			$end_date_output.addClass('red');
		} else {
			return_data.is_valid = true;

			$start_date_output.removeClass('red');
			$end_date_output.removeClass('red');
		}
	}

	return return_data;
};

function enableAddSubarticle (id) {

	$('#contractArticle_'+id).find('.add-subs-button').removeAttr('disabled');

	let html = `<svg class="icon" style="width: 13px; height: 13px; margin:5px 5px -2px 0px;">
                      <use xlink:href="#icon-add"></use>
                </svg>
                <div class="sub-addst">Subartikel hinzufügen</div>`;

	$('#contractArticle_'+id).find('.add-subs-button').text('');
	$('#contractArticle_'+id).find('.add-subs-button').append(html);
	$('#contractArticle_'+id).find('.add-subs-button').addClass('toHand').removeClass('toPointer');

}

// .............................................................................
// overwrites the return amount when wrong input used
// .............................................................................
$(function(){
    $('.return_anzahl').live('input change', function(){
        var newWrp = $(this);
        var newVal = parseInt(newWrp.val());
        var maxWrp = $(this).parents('tr').find('.orig_count');
        var maxVal = parseInt(maxWrp.text());
        //console.log('newVal: '+newVal+'\n'+'maxVal: '+maxVal);
        if(newWrp.length && maxWrp.length && (newVal < 0 || newVal > maxVal))
        {
            //console.log('overwriting the value');
            newWrp.val(maxVal);
        }
    });
});
// .............................................................................

$(function(){
    $('.to_return_anzahl_view').live('keyup', function(){
        if(!$(this).is('[readonly]'))
        {
            var qty_ret_val = $(this).attr('data-value');
            qty_ret_val = parseInt(qty_ret_val);
            var userRet_val = $(this).val();
            userRet_val = parseInt(userRet_val);
            //console.log('qty_ret_val: '+qty_ret_val+'\n'+'userRet_val: '+userRet_val);

            if(isNaN(userRet_val))
            return false;

            if(userRet_val <= 0)
            {
                userRet_val = 1;
            }
            if(userRet_val > qty_ret_val)
            {
                userRet_val = qty_ret_val;
            }

            $(this).val(userRet_val);
            $(this).parents('tr').find('.return_anzahl').val(userRet_val);
        }
    });
});

// shows some cutom alert
function showAlert(message, callback = false)
{
	var html = ''
				+'<div class="dash-block-header error-popup">'
				+'<div class="message-header-title text-uppercase">'
				+'Fehler'
				+'</div>'
				+'</div>'
				+'<div class="popup-content">'
				+'<br><p><b>'+message+'</b></p><br>'
				+'</div>'
			;

	openPopupOverPopup(html, '500px', callback);
}

// export section
window.add_new_pic = add_new_pic;
window.del_pic = del_pic;
window.openPopup = openPopup;
window.openPopupOverPopup = openPopupOverPopup;
window.closePopup = closePopup;
window.convertStringDate = convertStringDate;
window.convertPickerDateToTimestamp = convertPickerDateToTimestamp;
window.enableSaveButton = enableSaveButton;
window.disableSaveButton = disableSaveButton;
window.charsInArray = charsInArray;
window.showAlert = showAlert;
window.get_interval_from_date_wrapper = get_interval_from_date_wrapper;
window.enableAddSubarticle = enableAddSubarticle;
window.send_email = send_email;
window.validatePopupDates = validatePopupDates;