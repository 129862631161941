/* global $, openPopupOverPopup, showAlert */
$(document).ready(function () {

	function init_add_new_warehouse_location_option() {
		// console.log('.main-content.container select.warehouse_location: ' + $('select.warehouse_location').length)

		$(document).on('change', '.main-content.container select.warehouse_location', function() {
			let $this = $(this);

			let selectedWarehouseId = $this.val();

			// this option is added in the function selectWarehouseLocation
			if(selectedWarehouseId == 'add_new_warehouse_location') {
				// reset the selection
				$this.val(0);

				quickverleih.settings.system.show_add_new_location_popup(
					(server_response_data) => {
						// add the newly added warehouse id to all select elements
						$('.main-content.container select.warehouse_location')
							.find('option:last') // the last option is add_new_warehouse_location
							.before(new Option(server_response_data.name, server_response_data.id));

						// select the newly added warehouse id
						$this.val(server_response_data.id);
					}
				);
			}
		});
	}

	function set_auto_complete(for_new = false){
		var options = {
			adjustWidth: "false",
			url: "./includes/artikel/views/ArticleDetailsView.php?json=true",
			dataType: "json",
			getValue: "name",
			placeholder: "Bitte ein Merkmal eingeben",
			list: {
				match: {
					enabled: true
				}
			},
		};

		// set autocomplete for new inputs
		let selector = "input.article-spezi-attr";
		if(for_new) {
			selector = "input[id*='articleNewSpeziAttr']";
		}

		$(selector).easyAutocomplete(options);
	}

	init_add_new_warehouse_location_option();
	
	/* maybe reload table content after every new article */
	function editArticle(data) {
		$("#article").remove();
		$("#ArtTable").remove();
		$(".main-content.container").append(data);

		let table = $('#subArticleTable');

		let dataTablesOptions = setDataTablesOptions($(data));
		table.DataTable(dataTablesOptions);

		$('#subArticleTable_wrapper').addClass('one-full');

		if(table.data('noedit') == undefined) {
			// creates new and export buttons for subarticles
			const buttonMarkup = `<div id="subArticlesButtons" class="popup-div-content" >
											<div class="button submit-button-inactive" id="add_subarticle_number">NEUE UNTERARTIKELNUMMER</div>
											<div class="button submit-button-inactive button-right" id="export_ean_codes">ALLE EAN CODES EXPORTIEREN</div>
									</div>`;

			$('.data-table-header').after(buttonMarkup);
		}

		set_auto_complete();

		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0)
	}

	/*
	*	New Article popup
	*/
	$(document).on('click', '#new_article', function () {

		$.ajax({
			url: 'includes/artikel/ajax/ajaxArticle.php',
			type: 'POST',
			data: {
				method: 'createNewArticle'
			},
			success: function (data) {
				editArticle(data);
				$('#deleteArticle').addClass('hideButton');
			}
		});

		return false;

	});

  function updateBarcodes() {
	let articleNumber = $('#articleNumber').val()
	if(articleNumber.length !== 0) {
		$('.bar-code').each(function(index, img) {
			let $img = $(img);

			var article_number_for_call = articleNumber;

			// check if subarticle
			let $subarticle_number = $img.closest("tr").find('input.subarticle-number');
			if($subarticle_number.length === 1) {
				article_number_for_call += "-" + $subarticle_number.val();
			}

			$img.attr("src", "/components/Barcode/generate.php?article_number=" + article_number_for_call);
		});
	}
  }

	$(document).on('click', '#save_article', function () {
		if ($(this).hasClass('submit-button-disabled')) {
			return;
		}

		let errors = false;
		$('#basisDaten').find('[required]').each(function (index, elem) {
			let tempVal = $(elem).val();
			// console.log($(elem).attr('id') + ' -> ' + tempVal)

			if(typeof tempVal === 'undefined' || tempVal == '' || tempVal == 0 /* for select elements */) {
				errors = true;
				$(elem).addClass("field_error");
			}
		});

		let artId = $('#articleId').val();
		let articleNumber = $("#articleNumber").val();

		checkSubarticleNumbers(artId, articleNumber).then(({subarticleNumbersOk, existingSubarticlesNumbers}) => {

			if (subarticleNumbersOk === false) {
				if(existingSubarticlesNumbers.length > 0) {
					showDuplicateSubarticleNumbersError(articleNumber, existingSubarticlesNumbers);
				} else {
					var message = 'Bitte geben Sie Werte für alle Subartikelnummern ein.';
					showAlert(message);
				}

				errors = true;
			}

			if (errors === true) {
				return false;

			} else {
				// checks if the save button is disabled
				if ($('#save_article').css('submit-button-disabled')) {
					errors = true;
				}

				if (!errors) {
					var options = {
						success: function (data) {
							if(!data.success) {
								if(data.error == 'duplicate_article_number') {
									// mark article number as error
									$("#articleNumber").addClass('field_error');
									$('#articleNumberError').text('Artikelnummer ist bereits vorhanden.').removeClass('hidden');
								}

								if(data.error == 'duplicate_subarticles_numbers') {
									showDuplicateSubarticleNumbersError(articleNumber, data.error_data);
								}

								return;
							}

							// disable save button after article save
							$('#save_article').removeClass('submit-button');
							$('#save_article').addClass('submit-button-disabled');

							// todo: refactor to be saved in the first ajax call above, on the server
							addNewSubarticles(data.article_id, articleNumber)
								.then(() => {
									// for new articles go do overview
									if (artId === '0') {
										setTimeout(() => {
											window.location.replace('/index.php?page=4-2_Artikeluebersicht');
										}, 250);
			
										return;
									}
								});

							updateBarcodes();
						},

						url: 'includes/artikel/ajax/ajaxArticle.php',
						data: { method: 'saveArticle' },
						dataType: 'json'
					};

					$('#articleForm').ajaxSubmit(options);
				}
			}
		});
	});

	$(document).on('click', '.edit-article', function () {

		var articleId = $(this).attr('id-article');

		$.ajax({
			url: 'includes/artikel/ajax/ajaxArticle.php',
			type: 'POST',
			data: {
				articleId: articleId,
				method: 'editArticle'
			},

			success: function (data) {
				editArticle(data);
				$('#cancelArticle').addClass('hideButton');
			}
		});

	});

	$(document).on('click', '.search-art', function () {
		let filter = $(this).attr('gnr-article');
		window.location.href = '/index.php?page=4-2_Artikeluebersicht&filter=' + filter;
	});


	/*
	* Add New Spezi
	*/
	$(document).on('click', '#new_spezi', async function () {
		let new_specs_rows_count = $('#article_spezi_table .newspezi').length;

		let spezi_html = await quickverleih.api.render_php('./includes/artikel/ajax/ajaxArticle.php', {
			method : "new_spec_row_html",
            new_specs_rows_count,
        });

		$('#article_spezi_table tbody').append(spezi_html);

		set_auto_complete(true);
	});



	$(document).on('click', '.delete-article', function () {

		if ($(this).hasClass('delete-button')) {

			var articleId = $(this).attr('id-article');
			var articleName = $('#articleName').val();

			let data = `<div class="dash-block-header">
					<div class="message-header-title text-uppercase">Bestätigung Artikel löschen</div>
				</div>
				<div class="popup-content">
					<br>
					<p><b>Möchten Sie diesen Artikel löschen? :</b></p>
    				<p><b>${articleName}</b></p>
    				<br>
    				<div class="button delete-button delete-article-yes" data-id=${articleId}>ARTIKEL LÖSCHEN?</div>
    				<a href="#close-modal" rel="modal:close">
        				<div class="button white-button delete-article-no" style="float: right">ABBRECHEN</div>
    				</a>
    			</div>`;

			openPopupOverPopup(data, '500px');
		}

	});

	$(document).on('click', '.delete-article-yes', function () {

		var articleId = $(this).attr('data-id');

		$.ajax({
			url: 'includes/artikel/ajax/ajaxArticle.php',
			type: 'POST',
			dataType: 'html',
			data: {
				articleId: articleId,
				method: 'deleteArticle'
			},
			success: function (data) {
				let message = 'Der Artikel wurde gelöscht.';

				let html = `<div class="dash-block-header">
    						<div class="message-header-title text-uppercase">Bestätigung Artikel löschen</div>
						</div>
						<div class="popup-content">
    						<br><p><b>${message}</b></p><br>
						</div>`;

				openPopup(html, '500px');

				setTimeout(function () {
					window.location.href = '/index.php?page=4-2_Artikeluebersicht';
					closePopup();
				}, 2000);
			}
		});

	});


	/**
	 *  Sub-article Delete Modal
	 */


	$(document).on('click', '.delete-subarticle-number', function () {

		$('#subArticleTable').find('.delSelect').removeClass('delSelect');

		let subArticleId = $(this).attr('id-subarticle');

		let data = `<div class="dash-block-header">
    					<div class="message-header-title text-uppercase">Bestätigung Unterartikel löschen</div>
					</div>
					<div class="popup-content">
					<br>
					<p><b>Möchten Sie den Unterartikel wirklich löschen?</b></p>
					<br>
   						<div class="button delete-button delete-subarticle-yes" data-id="${subArticleId}">UNTERARTIKEL LÖSCHEN</div>
    					<a href="#close-modal" rel="modal:close">
        					<div class="button white-button delete-subarticle-no" style="float: right">ABBRECHEN</div>
    					</a>
					</div>`;

		openPopupOverPopup(data, '500px');

		$(this).closest('tr').addClass('delSelect');

	});

	/**
	 *  Sub-article Delete
	 */


	$(document).on('click', '.delete-subarticle-yes', function () {

		// Lowers Lagerbestand by 1
		$('#articleQty').val(function (i, oldval) {
			return --oldval;
		});

		let subArticleId = $(this).attr('data-id');
		let articleId = $('#articleId').val();
		let subArtQty = $('#articleQty').val();

		if (subArtQty < 0) {
			$('#articleQty').val(0);
		}

		if ($("input[id^='subarticle_number_new_subId_']").closest('tr').hasClass('delSelect')) {

			let saveArray = $('#new_subarticle_ids').val().split(',');

			saveArray.forEach(function (tempId) {
				if (subArticleId === tempId) {
					let index = saveArray.indexOf(subArticleId);
					saveArray.splice(index, 1);
				}
				$('#new_subarticle_ids').val(saveArray);
			});

			let message = 'Der Unterartikel wurde gelöscht.';
			let html = `<div class="dash-block-header">
										<div class="message-header-title text-uppercase">Bestätigung Unterartikel löschen</div>
									</div>
									<div class="popup-content">
									<br><p><b>${message}</b></p><br>
									</div>`;

			openPopup(html, '500px');
			setTimeout(function () {
				$('#subArticleTable').DataTable().row('.delSelect').remove().draw();
				closePopup();
			}, 2000);

			enableSaveButton('#save_article');

		} else {

			$.ajax({
				url: 'includes/artikel/ajax/ajaxArticle.php',
				type: 'POST',
				dataType: 'html',
				data: {
					articleId: articleId,
					subArticleId: subArticleId,
					method: 'deleteSubArticle'
				},
				success: function (data) {

					let message;

					if (data == true) {
						message = 'Der Unterartikel wurde gelöscht.';

						let html = `<div class="dash-block-header">
										<div class="message-header-title text-uppercase">Bestätigung Unterartikel löschen</div>
									</div>
									<div class="popup-content">
									<br><p><b>${message}</b></p><br>
									</div>`;

						openPopup(html, '500px');
						setTimeout(function () {
							$('#subArticleTable').DataTable().row('.delSelect').remove().draw();
							closePopup();
						}, 2000);

						// enableSaveButton('#save_article');

					} else {
						message = 'Ein Problem ist aufgetreten. Bitte prüfen Sie Ihre Datenverbindung';

						let html = `<div class="popup-content">
									<br><p><b>${message}</b></p><br>
									</div>`;

						openPopup(html, '500px');

					}
				}
			});
		}

		// removed the last one
		if ($('#subArticleTable').find('.delete-subarticle-number').length === 1) {
			$('#articleQty').attr("readonly", false);
			$('#articleHasSubarticles').prop('checked', false);
			$('.subArticlesContainer').attr('hidden', true);
		}

	});

	function add_pic() {

		post_parameters = {}
		post_parameters.idmaterial = idmaterial;

		$.post("includes/artikel/ajax/add_pic.php",
			post_parameters,
			function(data) {
				openPopupOverPopup(data, '500px');
			}
		);
	}

	$(document).on('click', '.fileupload-trigger', function () {
		add_pic();

		return false;
	});

	$(document).on('click', '.delete-article-spezi', function () {

		var articleSpeziId = $(this).attr('id-spezi');
		var articleSpeziAttr = $(this).parents('tr').find('.article-spezi-attr').val();

		var data = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
		data += 'Möchten Sie die Spezifikation zu diesem Artikel löschen?';
		data += '</div></div>';
		data += '<div class="popup-content">';
		data += '<br><p><b>' + articleSpeziAttr + '</b></p><br>';
		data += '<div class="button delete-button delete-article-spezi-yes" data-id="' + articleSpeziId + '">Löschen' /*+ qvapp.i18n.t('remove_article_from_contract_remove') */ + '</div>';
		data += '<a href="#close-modal" rel="modal:close"><div class="button white-button delete-article-spezi-no" style="float: right">Abbrechen' /*+ qvapp.i18n.t('remove_article_from_contract_cancel') */ + '</div></a>';

		openPopupOverPopup(data, '500px');

	});


	$(document).on('click', '.delete-article-spezi-yes', function () {

		var articleSpeziId = $(this).attr('data-id');
		var articleId = $('#articleId').val();

		if (articleId > 0) {	// delete from DB
			$.ajax({
				url: 'includes/artikel/ajax/ajaxArticle.php',
				type: 'POST',
				async: false,
				data: {
					articleSpeziId: articleSpeziId,
					articleId: articleId,
					method: 'deleteArticleSpezi'
				},
				success: function (data) {
					// confirmation
					var title = 'Bestätigung Artikelspezifikation löschen';
					var content = '<br><p><b>' + data + '</b></p><br>';
					$('#popup1 .message-header-title').html(title);
					$('#popup1 .popup-content').html(content);
				}
			});

			// remove spezi from DOM
			$('#articleSpeziId_' + articleSpeziId).parent().parent().remove();

		} else {
			//remove spezi from DOM
			$(this).parent().parent().remove();
		}

	});

	/* delete not saved spezi */
	$(document).on('click', '.delete-article-spezi-NEW', function () {
		$(this).parents('tr').remove();
	});


	/* Open New Article popup if we have NEU param in URL */
	if ($('#new_article_param').val() == 1) {
		$('#new_article').click();
	}


	$(document).on('click', '.delete-article-pic', function () {

		var articlePic = $(this).attr('pic');

		var data = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
		data += 'Möchten Sie das Artikelbild löschen?';
		data += '</div></div>';
		data += '<div class="popup-content"><div style="text-align: center; margin-bottom: 1rem">';
		data += '<br><img src="../storage/products/' + articlePic + '" style="max-width: 14rem; max-height: 14rem" alt="Artikelbild"><br>';
		data += '</div><div class="button delete-button delete-article-pic-yes" data-id="' + articlePic + '">ARTIKELBILD LÖSCHEN' /*+ qvapp.i18n.t('remove_article_from_contract_remove') */ + '</div>';
		data += '<a href="#close-modal" rel="modal:close"><div class="button white-button delete-article-pic-no" style="float: right">ABBRECHEN' /*+ qvapp.i18n.t('remove_article_from_contract_cancel') */ + '</div></a>';

		openPopupOverPopup(data, '500px');

	});

	$(document).on('click', '.delete-article-pic-yes', function () {

		var articlePic = $(this).attr('data-id');

		del_pic(articlePic);

		// confirmation
		var title = 'Bestätigung zur Löschung des Artikelbildes';
		var content = '<br><p><b>Das Artikelbild wurde gelöscht</b></p><br>';
		$('#popup1 .message-header-title').html(title);
		$('#popup1 .popup-content').html(content);
	});

	$(document).on('keyup', '#articleNumber', function () {
		var acceptable = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '-', '.', ' ', '$', '/', '+', '%'];
		var allowed = charsInArray($(this).val(), acceptable);

		if (allowed) {
			$(this).removeClass('field_error');
			$('#articleNumberError').addClass('hidden');
		} else {
			$(this).addClass('field_error');
			$('#articleNumberError').text('Artikelnummer enthält ein nicht-erlaubtes Zeichen.').removeClass('hidden');
		}

	});

	$(document).on('change', '#articleCategory', function () {
		const $this = $(this);
		if($this.val() == 0) {
			$this.addClass('field_error');
		} else {
			$this.removeClass('field_error');
		}
	});

	// - Today's date dd.mm.yy

	// - New Comment
	$(document).on('click', '.new-comment', function () {

		$(this).closest('.subArtButton').addClass('hidden');
		$(this).closest('.subArtButton').siblings('.new-comment').removeClass('hidden');
		$(this).closest('.subArtButton').siblings('.new-comment').find('.subArtComment').removeClass('comment-warning').addClass('comment-color');

		$(this).closest('tr').find('.delete-subarticle-number').addClass('hidden');


	});

	// -  Cancel Comment

	$(document).on('click', '.cancel-button', function () {

		$(this).closest('tr').find('.delete-subarticle-number').removeClass('hidden');
		$(this).closest('.subArtInputs').find('.subArtComment').val('');

		$(this).closest('.new-comment').addClass('hidden');
		$(this).closest('.new-comment').siblings('.subArtButton').removeClass('hidden');

	});


	$(document).on('click', '.cancel-button-edit', function () {

		let oldText = $(this).closest('.comment-container').find('.comment-text').text();
		let userAndDate = $(this).closest('.comment-container').find('.comment-date').text();

		$(this).closest('tr').find('.delete-subarticle-number').removeClass('hidden');
		$(this).closest('.subArtInputs').addClass('hidden');
		$(this).closest('.comment-container').find('.comment-box').removeClass('hidden');
		$(this).closest('.comment-container').find('.comment-text').text(oldText);
		$(this).closest('.comment-container').find('.comment-date').text(userAndDate);

	});

	// - Edit Comment


	$(document).on('click', '.edit-comment', function () {

		let comment = $(this).siblings('.comment-text')[0].innerText;

		$(this).closest('.comment-box').addClass('hidden');
		$(this).closest('.comment-box').siblings('.subArtInputs').removeClass('hidden');
		$(this).closest('tr').find('.delete-subarticle-number').addClass('hidden');
		$(this).closest('.comment-box').siblings('.subArtInputs').find('.subArtComment').val(comment);

	});


	/**
	 *	Delete comment modal
	 */



	$(document).on('click', '.delete-comment', function () {

		let commentId = this.getAttribute('id-comment');
		let subId = $(this).attr('id-subarticle');

		let data = `<div class="dash-block-header"> 
    					<div class="message-header-title text-uppercase">Bestätigung Kommentar löschen</div>
					</div>
					<div class="popup-content">
					<br>
					<p><b>Möchten Sie den Kommentar wirklich löschen?</b></p>
					<br>
   						<div class="button delete-button delete-comment-yes" comment-id ="${commentId}"  sub-id="${subId}">KOMMENTAR LÖSCHEN</div>
   						<a href="#close-modal" rel="modal:close">
       						<div class="button white-button delete-subarticle-no" style="float: right">ABBRECHEN</div>
   						</a>
					</div>`;

		openPopupOverPopup(data, '500px');

	});

	/**
	 * 	Delete comment -confirmation
	 */

	$(document).on('click', '.delete-comment-yes', function () {

		let subId = $(this).attr('sub-id');
		let commentId = $(this).attr('comment-id');

		if ($("div[id^='comment_number_new_comId_']").length) {

			let saveArray = $('#new_comment_ids').val().split(',');

			saveArray.forEach(function (tempId) {
				if (commentId === tempId) {
					let index = saveArray.indexOf(commentId);
					saveArray.splice(index, 1);
				}
				$('#new_comment_ids').val(saveArray);

				let message = "Der Kommentar wurde gelöscht.";
				yesCommentDelete(commentId, message)

			})

		} else {

			$.ajax({
				url: 'components/SubArticleComments/ajax/ajax.php',
				type: 'POST',
				data: {
					subId: subId,
					commentId: commentId,
					method: 'deleteComment'
				},
				success: function (data) {
					yesCommentDelete(commentId, data);
				}
			});
		}
	});

	$(document).on('keyup change', '.subarticle-number', function () {

		let $this = $(this);
		let currentVal = $(this).val();

		let $subarticleNumbers = $('.subarticle-number');

		// if only one field, enable the save button with every change
		if($subarticleNumbers.length == 1) {
			enableSaveButton('#save_article');
			return;
		}

		let is_duplicate = false;
		$subarticleNumbers.not($this).each(function () {
			if ($(this).val() == currentVal) {
				is_duplicate = true;
				return false;
			}

		});

		if (is_duplicate) {

			$this.css('border-color', '#eb547c');
			$this.addClass('warning-error');

			disableSaveButton('#save_article');
		} else {

			$this.css('border-color', '#eaeaea');
			$this.removeClass('warning-error');

			enableSaveButton('#save_article');
		}
	});
});

function showDuplicateSubarticleNumbersError(articleNumber, subarticleNumbers, callback = false) {
	var message = 'Folgende Subartikelnummern existieren bereit: ';

	var listOfSubarticleNumbers = "";
	subarticleNumbers.forEach(subarticleNumber => {
		if(listOfSubarticleNumbers.length > 0) {
			listOfSubarticleNumbers += ", ";
		}

		listOfSubarticleNumbers += articleNumber + "-" +subarticleNumber;
	});

	message += listOfSubarticleNumbers;

	showAlert(message, callback);
}

function yesCommentDelete(commentId, data) {

	let html = `<div class="dash-block-header">
    							<div class="message-header-title text-uppercase">Bestätigung Kommentar löschen</div>
						   </div>
						   <div class="popup-content">
    							<br><p><b>${data}</b></p><br>
						   </div>`;
	openPopup(html, '500px');

	setTimeout(function () {
		closePopup();
		$("#subArticleTable").find("[id-comment='" + commentId + "']").first().closest('.comment-container').remove();
	}, 2000);
}
/*
* Article Functions
*/

/*
* Check Subarticle Numbers For Empty Fields and if they already exist
* return promuse that resolves with an object containing a bool and array of duplicated numbers (returned from server)
*/
function checkSubarticleNumbers(articleId, articleNumber) {

	var numbersOk = true;

	let subarticlesNumbers = [];
	$('.subarticle-number').each(function (i, el) {
		if (el.value == '' || (el.value !== '' + parseInt(el.value, 10))) {
			numbersOk = false;
			$('#' + el.id).addClass('input-error');
		} else {
			$('#' + el.id).removeClass('input-error');

			subarticlesNumbers.push(el.value);
		}
	});

	if(numbersOk && subarticlesNumbers.length > 0) {
		return quickverleih.article_numbers.api.checkIfSubarticleNumbersExist(articleId, articleNumber, subarticlesNumbers)
			.then(({success, existingSubarticlesNumbers}) => {
				// there should be no subarticles' numbers that are already on the server
				let subarticleNumbersOk = success && existingSubarticlesNumbers.length == 0;
				return {subarticleNumbersOk, existingSubarticlesNumbers}
			});
	}

	return new Promise((resolve) => resolve({subarticleNumbersOk: numbersOk, existingSubarticlesNumbers: []}));
}

function theDate() {

	let today = new Date();
	let dd = today.getDate();
	let mm = today.getMonth() + 1;
	let yy = today.getFullYear().toString().substr(-2);
	if (dd < 10) {
		dd = '0' + dd
	}
	if (mm < 10) {
		mm = '0' + mm
	}
	today = dd + '.' + mm + '.' + yy;

	return today;
}

function addNewSubarticles(articleId, articleNumber) {
	return new Promise((resolve) => {
		let tempSubIds = $('#new_subarticle_ids').val();
		let newSubArray = tempSubIds.split(',');
		let subarticles = [];

		if (!articleId) {
			articleId = $('#articleId').val();
		}

		if (newSubArray.length > 0) {

			newSubArray.forEach(function (tempId) {
				if (tempId !== '') {
					let subarticle_number = $('#subarticle_number_' + tempId).val();
					let warehouse_location = $('#warehouse_location_' + tempId).val();
					let is_active = $('#is_subarticle_active_' + tempId).is(':checked');

					subarticles.push({
						subarticle_number,
						warehouse_location,
						is_active
					});
				}
			});

			$.ajax({
				url: 'includes/artikel/ajax/ajaxArticle.php',
				type: 'POST',
				data: {
					articleId: articleId,
					articleNumber,
					subarticles: subarticles,
					method: 'addNewSubArticles'
				},
				success: function (data) {

					let response = JSON.parse(data);

					if(response.success) {
						let newIds = response.newSubarticlesIds;

						$(newIds).each(function (key) {

							$('.subarticle-number').each(function () {

								if ($(this).val() === subarticles[key]) {

									$(this).closest('tr').find('#subArtId').val(+newIds[key]);
									$(this).attr('id', 'subarticle_number_' + newIds[key]);
									$(this).attr('name', 'subarticle_number[' + newIds[key] + ']');
								}
							});

						});

						saveComments();

						resolve();
					} else {
						let existingSubarticlesNumbers = response.existingSubarticlesNumbers;
						showDuplicateSubarticleNumbersError(articleNumber, existingSubarticlesNumbers,
							() => resolve());
					}
				}
			});
		}

		function saveComments() {

			let tempComIds = $('#new_comment_ids').val();
			let saveComArray = '';
			let userName = $('#user_name').val();

			if (tempComIds) {
				saveComArray = tempComIds.split(',');
			}


			$('.subArtComment').each(function () {
				if ($(this).is(":visible") && $(this).val().length > 0) {

					let that = this;
					let comText = $(this).val();
					let comId = $(this).closest('.comment-container').find('.delete-comment').attr('id-comment');
					let subId = $(this).closest('tr').find('#subArtId').val();
					let comData;

					// Edit Comment
					if (comId) {

						comData = { "comId": comId, "subId": subId, "commText": comText, "userName": userName };

						$.ajax({
							url: 'components/SubArticleComments/ajax/ajax.php',
							type: 'POST',
							data: {
								data: comData,
								method: 'editComment'
							},

							success: function (data) {
								$('#new_comment_ids').val('');

								$(that).closest('.new-comment').addClass('hidden');
								$(that).closest('.new-comment').siblings('.subArtButton').removeClass('hidden');
								$(that).closest('tr').find('.delete-subarticle-number').removeClass('hidden');

								$(that).closest('.subArtInputs').addClass('hidden');
								$(that).closest('.comment-container').find('.comment-box').removeClass('hidden');
								$(that).closest('.comment-container').find('.comment-text').text(comText);
								$(that).closest('.comment-container').find('.comment-date').text(userName + " | " + theDate())

							}
						});


					} else {  // New Comment

						comData = { "subId": subId, "commText": comText, "userName": userName };

						$.ajax({
							url: 'components/SubArticleComments/ajax/ajax.php',
							type: 'POST',
							data: {
								data: comData,
								method: 'saveComment'
							},

							success: function (data) {

								let newComId = JSON.parse(data);

								let newSubId = $('#comment_number_' + saveComArray).closest('tr').find('#subArtId').val();
								$('#comment_number_' + saveComArray).closest('.comment-container').find('.edit-comment, .delete-comment, .update-comment').attr('id-comment', newComId).attr('id-subarticle', newSubId);
								$('#comment_number_' + saveComArray).attr('id', 'comment_number_' + newComId);

								$('#new_comment_ids').val('');

								let newComment = addNewComment(newComId, comText, userName, theDate(), subId);
								$(newComment).insertBefore($(that).closest('.new-comment').siblings('.subArtButton'));

								$(that).closest('.new-comment').addClass('hidden');
								$(that).closest('.new-comment').siblings('.subArtButton').removeClass('hidden');
								$(that).closest('tr').find('.delete-subarticle-number').removeClass('hidden');

							}
						});
					}
				}
			});

			function addNewComment(comId, comText, userName, date, subId) {

				let commentBody = `<div class="comment-container">

												<div class="flex comment-box">
													<div class="flex__align--center comment-text" id="${comId}" >${comText}</div>
													<div class="comment-date comment-button flex__align--center" >${userName} | ${date}</div>

													<button type="button" class="reset-button data-table-content-action-button edit-comment svg-padding"  id-comment ="${comId}" id-subarticle="${subId}">
														<svg class="icon edit-icon" style="width:20px; height:20px;">
															<use xlink:href="#icon-pen"></use>
														</svg>
													</button>

													<button type="button" class="reset-button x-button delete-comment" id-comment ="${comId}" id-subarticle="${subId}">
														<svg class="icon" style="width: 20px; height: 20px;">
															<use xlink:href="#icon-x"></use>
														</svg>
													</button>
												</div>

												<div class="subArtInputs hidden flex">
													<input type="text" placeholder="Geben Sie hier Ihre Notiz ein" class="subArtComment edit" style="width: 50%;height: 100%">

													<div class="cancel-comment " style="height: 100%" >
														<div class="button white-button cancel-button-edit" style="float: right; margin-top: -7px ">ABBRECHEN</div>
													</div>
												</div>

											</div>`;

				return commentBody;

			}
		}

	});
}


$(document).on('ready', function () {

	let params = getParameter('filter');

	if (!params) {
		params = '';
	} else {

		if (params.includes('-')) {
			params = params.substr(0, params.indexOf('-'));
		}

		$('.data-table-name').text(' ARTIKEL ' + params);
		let resetButton = `<div class="button submit-button resetFilter" style="display: flex; align-items: center; justify-content: center; float: right; margin-left: 30px">Zeige mehr Artikel</div>`;
		$('.data-table-name').parent().append(resetButton);

	}

	// ArticleTableLoad(params);

	$('#articleTable_filter').find('input').on('keypress', function (e) {

		let key = e.which || e.keyCode;
		if (key === 13) {

			ArticleTableLoad(this.value);
		}
	});

	$('.resetFilter').on('click', function () {
		ArticleTableLoad('');
		$('.data-table-name').text(' ARTIKEL');

		let uri = window.location.toString();
		if (uri.indexOf("?") > 0) {
			let clean_uri = uri.substring(0, uri.indexOf("?"));
			window.history.replaceState({}, document.title, clean_uri);
		}

	});
});


function ArticleTableLoad(filter) {

	let critStock = false;

	if ($('#articleTable_filter').closest('#crit_wrap_id').length) {
		critStock = true;
		$('#ArticlesTableWrapper').addClass('loading');
	}

	$('#ArtTable').addClass('loading');

	$.ajax({
		type: "GET",
		url: "includes/artikel/ajax/ArticlesTableRowsView.php?" + "&article_filter=" + filter + "&crit_stock=" + critStock,
		success: function (data) {

			let table = $('#articleTable').DataTable();

			table.clear();

			$(data).each(function () {

				if ($(this).is('tr')) {

					table.row.add($(this));
				}
			});

			table.draw();

			setTimeout(function () {

				$('#ArtTable').removeClass('loading');
				$('#ArticlesTableWrapper').removeClass('loading');
			}, 200);
		}
	});

}

function getParameter(theParameter) {
	var params = window.location.search.substr(1).split('&');

	for (var i = 0; i < params.length; i++) {
		var p = params[i].split('=');
		if (p[0] == theParameter) {
			return decodeURIComponent(p[1]);
		}
	}
	return false;
}

function mark_as_valid($input) {
	enableSaveButton('#save_article');
	$input.removeClass('field_error');
}

function mark_as_not_valid($input) {
	disableSaveButton('#save_article');
	$input.addClass('field_error');
}

$(document).on('input', '#articleQty', function (e) {
	e.preventDefault();

	if (!$('#articleHasSubarticles').is(':checked')) {

		var $countInpput = $(this);

		let qty = parseInt($countInpput.val());
		let articleId = $('#articleId').val();

		if (isNaN(qty) || qty == 0) {
			mark_as_not_valid($countInpput);
			return;
		}

		// console.log('articleId',articleId);

		$.ajax({
			url: 'includes/artikel/ajax/ajaxArticle.php',
			type: 'POST',
			data: {
				articleId: articleId,
				method: 'getArticlesInUse'
			},
			success: function (data) {
				// console.log('data',parseInt(data));

				if (parseInt(data) >= qty) {

					console.log('cannot save');

					mark_as_not_valid($countInpput);

				} else {
					console.log('can save');

					mark_as_valid($countInpput);
				}



			}
		});

	}


});