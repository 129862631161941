/* global $, ENABLE_HOURS */

$(document).ready(function() {

	// Initial date will be today's date
	localStorage.setItem('ArticleStartDate', today());
	localStorage.setItem('ArticleStartTime', ENABLE_HOURS ? new Date().getHours().toString().padStart(2, '0') +":00" : "00:00");
	localStorage.setItem('ArticleEndDate', today());
	localStorage.setItem('ArticleEndTime', ENABLE_HOURS ? new Date().getHours().toString().padStart(2, '0') +":00" : "23:59");

/* CUSTOMER */
	/*
	* Adds a customer into contract from popup
	*/
	$(document).on('click', '.add-customer', function() {
		var customerId = $(this).attr('id').split('_');
		customerId = customerId[1];
		// console.log(customerId);

		if(_callback_selected_customer === null) {
			quickverleih.contract.add_customer_to_contract(customerId);
		} else {
			_callback_selected_customer(customerId);
			closePopup();
		}
	});

	function grayOutUsedArticles () {

		setTimeout(function(){
			if($('#contractArticleTable .add-article').length)
			{
				$('#contractArticleTable .add-article').each(function(dex, val){
					var this_xId = $(this).attr('id');
					//console.log('this_xId: '+this_xId);
					this_xId = this_xId.split('_');
					this_xId = this_xId.pop();
					//console.log('this_xId: '+this_xId);
					if($('#contractArticlesTable #artikel_'+this_xId).length)
					{
						$(this)
							.css({ 'opacity' : '0.25', 'cursor' : 'default' })
							.attr('title', 'Artikel bereits hinzugefügt');
					}
				});
			}
		}, 500);
	}

/* ARTICLE POPUP */
	/*
	* Opens a popup with articles table
	*/
	$(document).on('click', '#openArticleTable', function() {
		openArticleTable();
		grayOutUsedArticles();
	});

	/*
	* Adds an article into contract from popup
	*/
	$(document).on('click', '.add-article', function() {
		closePopup();
		
		var articleId = $(this).attr('id').split('_');
		articleId = articleId[1];

		// rc: not used - this check should be removed
		// .................................................
		// check all contract rows for an existing duplicate
		// .................................................
		var goodTOgo = true;
		var this_xId = '';
		if($('#contractArticlesTable .rental-article').length)
		{
			$('#contractArticlesTable .rental-article').each(function(dex, val){
				this_xId = $(this).attr('id');
				//console.log('this_xId: '+this_xId);
				this_xId = this_xId.split('_');
				this_xId = this_xId.pop();
				//console.log('this_xId: '+this_xId);
				if(this_xId == articleId)
				{
					goodTOgo = false;
				}
			});
		}
		if(typeof goodTOgo !== typeof undefined && goodTOgo === false)
		{
			//console.log('duplicated article found: '+this_xId);
			return;
		}
		// .................................................

		// get rental interval
		var interval = getIntervalFromAddArticlePopup();

		localStorage.setItem('ArticleStartDate', interval.start_date);
		localStorage.setItem('ArticleStartTime', interval.start_time);
		localStorage.setItem('ArticleEndDate', interval.end_date);
		localStorage.setItem('ArticleEndTime', interval.end_time);

		if(_article_ids_already_in_contract !== null && _article_ids_already_in_contract.includes(articleId)) {
			// console.log('article already in contract');
			return;
		}

		// set the article's number in contract
		var rental_articles_count = $('.rental-article').length;
		addArticleInContract(articleId, interval.start_date, interval.start_time, interval.end_date, interval.end_time, rental_articles_count);
	});

	/*
	* Change popup start-end interval and repopulate article table
	*/
	$(document).on('change', '#datepickerInsertFromDate, #datepickerInsertToDate', function(ev) {
		// console.log('Event change on "'+ '#datepickerInsertFromDate, #datepickerInsertToDate'+'" called with: ');
		// console.dir(ev);
		// return;
		// prevent reloading if some date is not defined
		if (typeof $('#datepickerInsertFromDate').val() != 'undefined' && typeof $('#datepickerInsertToDate').val() != 'undefined' && $('#datepickerInsertToDate').siblings(".datetimepicker-output").val() != "" ) {
			// console.log('Event change on "'+ '#datepickerInsertFromDate, #datepickerInsertToDate'+'" called with: ');
			// prevent reloading if interval is not valid
			var valid = validatePopupDates($(this));
			if (valid === true) {
				popupArticleTableReload();
			}
		} else {
			// Andy: hack to allow the pickers to load - needs some refactoring
			// setTimeout( function () {
			// 	$("#datepickerInsertFromDate").change();
			// }, 10);
		}

		grayOutUsedArticles();
	});

	/*
	* Deletes an article from contract
	*/
	$(document).on('click', '.delete-contract-article', function() {

	  	let contractArticleId = $(this).attr('id-contract-article');
	  	let contractArticleName = $(this).parent().parent().find('.contractArticleName').html();

	  	let data = `<div class="dash-block-header">
						<div class="message-header-title text-uppercase">${qvapp.i18n.t('remove_article_from_contract_question')}</div>
					</div>
						
					<div class="popup-content">
						
						<br>
							<p><b>${contractArticleName}</b></p>
						<br>
						
						<div class="button delete-button delete-contract-article-yes" data-id="${contractArticleId}">
							${qvapp.i18n.t('remove_article_from_contract_remove')}
						</div>
						<a href="#close-modal" rel="modal:close">
							<div class="button white-button delete-contract-article-no" style="float: right">
								${qvapp.i18n.t('remove_article_from_contract_cancel')}
							</div>
						</a>
					</div>`;

		openPopup(data, '700px');

	});

	$(document).on('click', '.delete-contract-article-yes', function() {

	  	let contractArticleId = $(this).attr('data-id');
		let contractArticleName = $('#contractArticle_'+contractArticleId).find('.contractArticleName').html();

		// confirmation
		let title = qvapp.i18n.t('remove_article_from_contract_confirmation');

		let content = `<br>
							<p>
								<b>${contractArticleName}</b>
							</p>
						<br>`;

		$('#popup .message-header-title').html(title);
		$('#popup .popup-content').html(content);

		// remove note from DOM - checks if recurring or not

		$('#contractArticle_'+contractArticleId).remove();

		enableSaveButton('#save_contract');
	});

	$(document).on('click', '.remove-handed-out-tag', function() {

		$(this).parent().hide();
		$(this).parent().parent().find('.article-handed-out').val(0).keyup();	//activate save button
	});

/* CONTRACT TEXTS */
	/*
	* Switch between contract text tabs
	*/
	$(document).on('click', '#showContractText', function() {

		if ($(this).hasClass('submit-button-inactive')) {

			$(this).removeClass('submit-button-inactive');
		  	$(this).addClass('submit-button');
		  	$('.contract-text-tab').removeClass('hidden');

		  	$('#showAgbText').removeClass('submit-button');
		  	$('#showAgbText').addClass('submit-button-inactive');
		  	$('.agb-text-tab').addClass('hidden');

		}

	});

	$(document).on('click', '#showAgbText', function() {

		if ($(this).hasClass('submit-button-inactive')) {

			$(this).removeClass('submit-button-inactive');
		  	$(this).addClass('submit-button');
		  	$('.agb-text-tab').removeClass('hidden');

		  	$('#showContractText').removeClass('submit-button');
		  	$('#showContractText').addClass('submit-button-inactive');
		  	$('.contract-text-tab').addClass('hidden');

		}

	});

	(function initReservationContractTable() {

		var contractTable    = $('#contractsTable');
		var table = contractTable.DataTable();

		// order current loans table after 'nummer'
		table.column('0').order('desc').draw();

		$('#contractsTable_filter input').off().on('keypress',function (e) {

			let key = e.which || e.keyCode;
			if (key === 13) {

				let init = true;
				let type = $('#contractsTable').attr('data-type');

				$('#ContractsTableWrapper').addClass('loading');

				$.ajax({
					type   : "GET",
					url    : "includes/vertraege/ajax/contract_search.php?" + "&contract_filter=" + this.value + "&contract_init=" + init + "&contract_type=" + type,
					success: function (data) {

						let table = $('#contractsTable').DataTable();

						table.clear();

						$(data).each(function () {

							if($(this).is('tr')){

								table.row.add($(this));
							}
						});

						table.draw();

						setTimeout(function () {

							$('#ContractsTableWrapper').removeClass('loading');
						}, 200);
					}
				});
			}
		});
	})();
});

function today() {

	let today = new Date();
	let dd = today.getDate();
	let mm = today.getMonth() + 1; //January is 0!
	let yyyy = today.getFullYear();

	if (dd < 10) {
		dd = '0' + dd;
	}

	if (mm < 10) {
		mm = '0' + mm;
	}

	today = dd + '.' + mm + '.' + yyyy;
	return today;

}


/*
* CONTRACT FUNCTIONS
*/

/*
* Opens a popup with customers table

	callback_selected_customer - if this is specified the selected customer id is passed to the callback (new logic)
	if not specified - the old app logic is executed
*/
let _callback_selected_customer = null
function openCustomerTable(callback_selected_customer = null) {
	if($("#openCustomerTable").attr('disabled') == 'disabled') {
		return;
	}

	_callback_selected_customer = callback_selected_customer;

  	$.ajax({
		url: 'includes/vertraege/ajax/ajaxContract.php',
		type: 'POST',
		data: {
			method: 'openCustomerTable'
		},
		success: function(data) {
			var html = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
				html+= 'Ausleiher Auswählen';
				html+= '</div></div>';
				html+= data;
				html+= '<br><br>';

			openPopup(html, '800px');
			var dataTablesOptions = setDataTablesOptions($('#CustomersTableWrapper').find('.data-table-block'));
			$('#contractCustomerTable').DataTable( dataTablesOptions );

			$('#contractCustomerTable_filter').find('input').on('keypress',function (e) {
				let key = e.which || e.keyCode;
				if (key === 13) {

					$('#CustomersTableWrapper').addClass('loading');

					$.ajax({
						type   : "GET",
						url    : "includes/vertraege/ajax/ContractCustomersTableRowsView.php?" + "&customer_filter=" + this.value,
						success: function (data) {

							let table = $('#contractCustomerTable').DataTable();

							table.clear();

							$(data).each(function () {

								if($(this).is('tr')){

									table.row.add($(this));
								}
							});

							table.draw();

							setTimeout(function () {

								$('#CustomersTableWrapper').removeClass('loading');
							}, 200);
						}
					});

				}

			});

		}
	});

}
/*
* Opens a popup with article table
*/
let _article_ids_already_in_contract = null;
let _callback_selected_article = null
function openArticleTable(article_ids_in_contract = null, callback_selected_article = null) {
	_article_ids_already_in_contract = article_ids_in_contract;
	_callback_selected_article = callback_selected_article;

	// get start - end dates
	var interval = getIntervalForAddArticlePopup();

  	$.ajax({
		url: 'includes/vertraege/ajax/ajaxContract.php',
		type: 'POST',
		data: {
			start_date: interval.start_date,
			start_time: interval.start_time,
			end_date: interval.end_date,
			end_time: interval.end_time,
			method: 'openArticleTable'
		},
		success: function(data) {
			var html = '<div class="dash-block-header"> <div class="message-header-title text-uppercase">';
				html+= 'Artikel Auswählen';
				html+= '</div></div>';
				html+= data;
				html+= '<br><br>';

			// show header
			openPopup(html, '1200px');

			var dataTablesOptions = setDataTablesOptions($('#ArticlesTableWrapper').find('.data-table-block'));
			$('#contractArticleTable').DataTable( dataTablesOptions );

			popupArticleTableReload();
		}
	});
}

// grays out articles that are already in contract
function grayOutArticlesInContract () {
	if(_article_ids_already_in_contract !== null) {

		for(const article_id of _article_ids_already_in_contract) {

			$(`#contractArticleTable #addArticle_${article_id}.add-article`)
				.css({ 'opacity' : '0.25', 'cursor' : 'default' })
				.attr('title', 'Artikel bereits hinzugefügt');
		}
	}
}

/*
* Repopulate popup article table
* specFilter is a json object containing key and value, used to filter articles by a specification (spezi) field
*/
function popupArticleTableReload(filter, specFilter = false) {

	setTimeout(function () {
		$('#ArticlesTableWrapper').removeClass('loading');
	}, 200);

	if( typeof filter === 'undefined' || filter === null ){
		filter = '';
	}

	// get start - end dates
	var interval = getIntervalFromAddArticlePopup();
	var dataTableWrapper = $('#ArticlesTableWrapper').find('.data-table-block');

  	$.ajax({
		url: 'includes/vertraege/ajax/ContractArticlesTableRowsView.php',
		type: 'POST',
		data: {
				start_date: interval.start_date,
				start_time: interval.start_time,
				end_date: interval.end_date,
				end_time: interval.end_time,
				filter : filter,
				spec_filter: specFilter
			},
		success: function(data) {

			var dataTablesOptions = setDataTablesOptions(dataTableWrapper);
			$('#contractArticleTable').DataTable().destroy();
			$('#contractArticleTable tbody').html(data);
			$('#contractArticleTable').DataTable( dataTablesOptions );
			$('#contractArticleTable_filter input').val(filter);

			// grays out articles that are already in contract
			grayOutArticlesInContract();
			
			// filter on enter as well
			$('#contractArticleTable_filter').find('input').on('keypress',function (e) {
				let key = e.which || e.keyCode;
				if (key === 13) {
					popupArticleTableReload(this.value);

					$('#ArticlesTableWrapper').addClass('loading');

				}
			});
		}
	});
}

/*
* Add a article into contract
*/
function addArticleInContract(articleId, start_date, start_time, end_date, end_time, i) {

	if(_callback_selected_article !== null) {
		_callback_selected_article(articleId, `${start_date} ${start_time}`, `${end_date} ${end_time}`);

		return;
	}

	var kundenid = $('#kundenid').val(),
		new_contract_counters = $('#new_contract').val(),	//check if it's a new contract
		idcontract_counters = $('#id_contract').val();		//if it's not a new contract, get id

  	$.ajax({
		url: 'includes/vertraege/ajax/ajaxContract.php',
		type: 'POST',
		data: {
			id: 'artikel',
			i: i,
			idmaterial: articleId,
			kundenid: kundenid,
			art: 1 /* rental */,
			start_date: start_date,
			start_time: start_time,
			end_date: end_date,
			end_time: end_time,
			article_count: 1,
			neu: new_contract_counters,
			idcontract: idcontract_counters,
			country_address: 'DE',
			method: 'addArticleInContract'
		},
		success: function(data) {

			quickverleih.storage.disable_availability_check = true;

			$('#contractArticlesTable').append(data);

			quickverleih.storage.disable_availability_check = false;

			let stock = $('#addArticle_'+articleId).find('.data-table-content-quantity-entry ').text().trim();
			$('#articleBestand_'+articleId).val(stock);
		}
	});

}

/*
* Gets start - end dates for inputs in Add Article Popup
*/
function getIntervalForAddArticlePopup() {

	let interval = '';

	// console.log("getIntervalForAddArticlePopup: ");
	// console.log(localStorage.getItem("ArticleStartDate"));
	// console.log(localStorage.getItem("ArticleStartTime"));
	// console.log(localStorage.getItem("ArticleEndDate"));
	// console.log(localStorage.getItem("ArticleEndTime"));

	if (localStorage.getItem("ArticleStartDate") === null) {
		interval = {
			start_date: today(),
			start_time: ENABLE_HOURS ? new Date().getHours().toString().padStart(2, '0') +":"+ new Date().getMinutes().toString().padStart(2, '0') : "00:00",
			end_date: today(),
			end_time: ENABLE_HOURS ? new Date().getHours().toString().padStart(2, '0') +":"+ new Date().getMinutes().toString().padStart(2, '0') : "23:59"
		};

	} else {
		interval = {
			start_date: localStorage.getItem("ArticleStartDate"),
			start_time: localStorage.getItem("ArticleStartTime"),
			end_date: localStorage.getItem("ArticleEndDate"),
			end_time: localStorage.getItem("ArticleEndTime")
		};

	}

	return interval;
}

/*
* Gets start - end dates for an Article added in Contract From Popup
*/
function getIntervalFromAddArticlePopup() {

	// check for start - end dates in popup
	let startPicker = convertPickerDateToTimestamp($("#datepickerInsertFromDate"));
	let endPicker = convertPickerDateToTimestamp($("#datepickerInsertToDate"));

	let startVal = $('#datepickerInsertFromDate').parent().find('.datetimepicker-output').val();
	let endVal = $('#datepickerInsertToDate').parent().find('.datetimepicker-output').val();

	let formatDate = 'd.m.Y';
	let formatTime = 'H:i';

	let start_date = convertStringDate(startPicker, formatDate);
	let end_date = convertStringDate(endPicker, formatDate);

	let start_time = ENABLE_HOURS ? ( (!isNaN(new Date(startVal)) ) ? convertStringDate(startVal, formatTime) : startVal.slice(-5)) : "00:00";
	let end_time = ENABLE_HOURS ? ( (!isNaN(new Date(endVal)) ) ? convertStringDate(endVal, formatTime) : endVal.slice(-5)) : "23:59";

	// console.log("getIntervalFromAddArticlePopup times from pickers:");
	// console.log("Start: "+start_date +" "+start_time);
	// console.log("End: "+end_date +" "+end_time);

	return {
		start_date: start_date, 
		start_time: start_time, 
		end_date: end_date,
		end_time: end_time
	};
}

function triggerBeforeUnload(document) {
	trigger = document.activeElement;
    console.log(trigger.id);
    if (trigger.id != "save_contract") {
        return "Möchten Sie diese Seite verlassen?";
    }
}

// removes subarticles from view
$(function(){

	$(document).on('click','.delete-subContr-article', function () {

		console.log('in');

        let contractArticleId = $(this).attr('data-article');
        let subContrArticleId = $(this).attr('data-subArtc');
        let subContrArticleNm = $(this).attr('data-subName');
        let subContrPositions = $(this).attr('data-numbers');
        let dataPos = $(this).attr('data-pos');
        let contract_Art_Name = $(this).parent().parent().find('.contractArticleName').html();
        let contract_ShowName = '<p><b>'+contract_Art_Name+'</b><b>'+subContrArticleNm+'</b></p>';

        let data = `<div class="dash-block-header">
                        <div class="message-header-title text-uppercase">${qvapp.i18n.t('remove_article_from_contract_question')}</div>
                    </div>
                    <div class="popup-content">
                        <br>${contract_ShowName}<br>
                        <div class="button delete-button delete-subContr-article-yes" data-article="${contractArticleId}" data-subName="${subContrArticleNm}" data-subArtc="${subContrArticleId}" data-numbers="${subContrPositions}" data-pos="${dataPos}">
                            ${qvapp.i18n.t('remove_article_from_contract_remove')}
                        </div>
                        <a href="#close-modal" rel="modal:close">
                            <div class="button white-button delete-contract-article-no" style="float: right">${qvapp.i18n.t('remove_article_from_contract_cancel')}</div>
                        </a>
                    </div>`;

		openPopup(data, '700px');
    });

    $('.delete-subContr-article-yes').live('click', function(){
        let contractArticleId = $(this).attr('data-article');
        let subContrArticleId = $(this).attr('data-subArtc');
        let subContrArticleNm = $(this).attr('data-subName');
        let subContrPositions = $(this).attr('data-numbers');
        let newlyAddedPos = $(this).attr('data-pos');
        let allSubarticlesIDs = $('#contractArticle_'+contractArticleId).find('.subarticle_ids').val();

		let contract_Art_Name = $('#contractArticle_'+contractArticleId).find('.contractArticleName').html();
		let contract_ShowName = `<p><b>${contract_Art_Name}</b> <b>${subContrArticleNm}</b></p>`;

        let language_modalTitle = qvapp.i18n.t('remove_article_from_contract_confirmation');

        $('#popup .message-header-title').html(language_modalTitle);
        $('#popup .popup-content').html('<br>'+contract_ShowName+'</p><br>');

        // decreases total subarticles counter
        let returnSNumber = $('#contractArticle_'+contractArticleId).find('.artikel_anzahl').val();

        if(!isNaN(returnSNumber) && returnSNumber != '') {
            returnSNumber = parseInt(returnSNumber);
            returnSNumber--;
            if(returnSNumber >= 0) {
                $('#contractArticle_'+contractArticleId).find('.artikel_anzahl').val(returnSNumber);
            }
        }

		// removes selected subarticle from view
		$('#contractArticle_'+contractArticleId).find('[id*="_'+subContrPositions+'"]').parents('.theSub').remove();
		$('#contractArticle_'+contractArticleId).find('[id*="_'+subContrPositions+'"]').remove();
		$('#contractArticle_'+contractArticleId).find('[id*="_'+subContrPositions+'_wrap"]').remove();
		$('#contractArticle_'+contractArticleId).find('[for*="_'+subContrPositions+'"]').remove();
		$('#contractArticle_'+contractArticleId).find('[id*="ret_S_Dates_val_'+subContrPositions+'"]').remove();

		if (newlyAddedPos){
			$('#contractArticle_'+contractArticleId).find('[id*="'+newlyAddedPos+'"]').parents('.theSub').remove();
			$('#contractArticle_'+contractArticleId).find('[id*="'+newlyAddedPos+'"]').remove();
			$('#contractArticle_'+contractArticleId).find('[id*="'+newlyAddedPos+'_wrap"]').remove();
			$('#contractArticle_'+contractArticleId).find('[for*="'+newlyAddedPos+'"]').remove();
			$('#contractArticle_'+contractArticleId).find('[id*="ret_S_Dates_val_'+newlyAddedPos+'"]').remove();
		}

		allSubarticlesIDs = allSubarticlesIDs.replace(subContrArticleId+';', '');
		allSubarticlesIDs = allSubarticlesIDs.replace(';'+subContrArticleId, '');
		allSubarticlesIDs = allSubarticlesIDs.replace(subContrArticleId, '');
		$('#contractArticle_'+contractArticleId).find('.subarticle_ids').val(allSubarticlesIDs);

		if ($('.delete-subContr-article[data-article="'+contractArticleId+'"]').length < 1){
			$('#contractArticle_'+contractArticleId).remove();
		}

		enableSaveButton('#save_contract');
		enableAddSubarticle(contractArticleId);
	});
	

	quickverleih.contract = (function () {
		/*
		* Add a customer into contract
		* ignoreEdit, can be set to true, to ignore the edit_customer sent by server. as in the case where the 
		*/
		var add_customer_to_contract = function(customerId, ignoreEdit = false) {

			$.ajax({
				url: 'includes/vertraege/ajax/ajaxContract.php',
				type: 'POST',
				dataType: 'json',
				data: {
					customerId: customerId,
					ignoreEdit: ignoreEdit,
					method: 'addCustomerInContract'
				},
				success: function(data) {

					// this is set to true on the server if the customer needs to be edited
					if(!ignoreEdit && data.edit_customer) {
						openPopup(data.html, '800px');
						$('#save_customer').data('edit_from_contract', 1);
					} else {
						$('#customerInContractWrapper').html(data.html);
						closePopup();

						enableSaveButton('#save_contract');
					}
				}
			});
		}

		return {
			add_customer_to_contract: add_customer_to_contract
		};
	})();
});

// export section
window.openCustomerTable = openCustomerTable;
window.openArticleTable = openArticleTable;
window.popupArticleTableReload = popupArticleTableReload;