
quickverleih.article_numbers = quickverleih.article_numbers || {};

quickverleih.article_numbers.api = (function () {

    function checkIfSubarticleNumbersExist(articleId, articleNumber, subarticlesNumbers) {

        let data = {
            method: 'checkIfSubarticleNumbersExist',
            articleId,
            articleNumber,
            subarticlesNumbers
        };

        return quickverleih.api.callServer('includes/artikel/ajax/ajaxArticle.php', 'POST', data);
    }

    return {
        checkIfSubarticleNumbersExist
    };
})();