quickverleih = quickverleih || {}

quickverleih.editor = (function () {

    function init(selector_id, $submitButton) {
        $(selector_id)
            .summernote({
                lang: 'de-DE',
                height: 200,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'clear']],
                    ['format', ['fontsize', 'color']],
                    ['para', ['paragraph']],
                    // ['delimitor', ['hr']],
                    // ['table', ['table']]
                    // ['code', ['codeview']]
                ]
            })
            .on('summernote.change', function(we, contents, $editable) {
                $submitButton
                    .removeClass('submit-button-disabled')
                    .addClass('submit-button')
                    .enable();
            });
    }

    return {
        init: init
    }
})();