
quickverleih.settings = quickverleih.settings || {};
quickverleih.settings.system = (function () {

    let _selector = '#locations_table';
    var _content = null;

    function init() {
        _content = $(_selector);

        _add_new_location();
        _edit_location()
        _delete_location();
    }

    function _show_add_new_location_popup(callback_after_location_add) {
        var popupContent = quickverleih.template.render('./includes/einstellung/templates/location_popup._', {
            mode : "",
            location: {},
        });

        let popup = openPopup(popupContent, '400px');

        let add_button = popup.find('.save-location');
        add_button.off('click').on('click', function () {

            if (_validateInputs(popup) === false) {
                return;
            }

            let location_data = _get_popup_data(popup);
            quickverleih.settings.api.addLocation(location_data, (server_response) => {
                if(!server_response.ok) {
                    var message = server_response.message;
                    showAlert(message);
                    return false;
                }

                callback_after_location_add(server_response.data);

                closePopup();
            });
        });
    }

    function _add_new_location() {

        var _location_added_successfull = (server_response_data) => {
            

            var locations_table = _content;
            var dataTable = locations_table.DataTable();

            let edit_button = 
                `<button type="button"
                    data-location-id="${server_response_data.id}"
                    class="reset-button data-table-content-action-button edit-location">

                    <svg class="icon" style="width: 18px; height: 18px;">
                        <use xlink:href="#icon-pen"></use>
                    </svg>
                </button>`;
            let delete_button = 
                `<button type="button"
                    data-location-name="${server_response_data.name}" 
                    data-location-id="${server_response_data.id}"
                    class="reset-button x-button delete-location">

                    <svg class="icon" style="width: 13px; height: 13px;">
                        <use xlink:href="#icon-x"></use>
                    </svg>
                </button>`;

            dataTable.row.add([
                server_response_data.name,
                server_response_data.address,
                server_response_data.contact_person,
                server_response_data.email,
                edit_button,
                delete_button,
            ]).draw(false);

            // reload these 2 functions
            _edit_location();
            _delete_location();
        }

        var _on_add_new_location = function (e) {
            _show_add_new_location_popup(_location_added_successfull);
        };

        var new_location_button = _content.find('.add-new-location');
        new_location_button.off('click').on('click', _on_add_new_location);
    }

    function _edit_location() {

        var _location_saved_successfull = ($trParent, server_response) => {
            if(!server_response.ok)
            {
                var message = server_response.message;
                showAlert(message);
                return false;
            }

            var locations_table = _content;
            var dataTable = locations_table.DataTable();
            var rowData = dataTable.row($trParent).data();

            rowData[0] = server_response.data.name;
            rowData[1] = server_response.data.address;
            rowData[2] = server_response.data.contact_person;
            rowData[3] = server_response.data.email;

            dataTable.row($trParent).data(rowData).draw(false);

            // reload these 2 functions
            _edit_location();
            _delete_location();
           
            closePopup();
        }

        var _on_edit_location = function (e) {
            let $this = $(this);
            var location_id = $this.data("location-id");

            quickverleih.settings.api.getLocation(location_id, (response) => {
                if(!response.ok) {
                    var message = response.message;
                    showAlert(message);
                    return false;
                }

                let location_data = response.location;

                var popupContent = quickverleih.template.render('./includes/einstellung/templates/location_popup._', {
                    mode : "edit",
                    location: location_data,
                });
                
    
                let popup = openPopup(popupContent, '400px');
    
                let save_button = popup.find('.save-location');
                save_button.off('click').on('click', function () {
    
                    if (_validateInputs(popup) === false) {
    
                        return;
                    }
    
                    let edited_location_data = _get_popup_data(popup);
                    edited_location_data.id = location_id;

                    quickverleih.settings.api.saveLocation(edited_location_data,
                        (server_response) => {
                            _location_saved_successfull($this.parents('tr').first(), server_response);
                        }
                    );
                });
            });
        };

        var edit_location_button = _content.find('.edit-location');
        edit_location_button.off('click').on('click', _on_edit_location);
    }

    function _validateInputs(popup) {

        let ids_to_check = ['#location_name', '#location_address', '#location_contact_person', '#location_email'];

        let index_with_empty_values = _.findIndex(ids_to_check, (input_id) => {
            let $input = popup.find(input_id);

            return $input.val().length === 0
        });

        // no index found with empty value
        return index_with_empty_values === -1;
    }

    function _get_popup_data(popup) {

        let data_elements = ['name', 'address', 'contact_person', 'email'];
        let popup_data = {};

        _.each(data_elements, (element) => {
            let $input = popup.find(`#location_${element}`);

            popup_data[element] = $input.val()
        });

        return popup_data;
    }

    
    function _delete_location() {

        var _deleteEmpFn = function (e) {
            var $elem = $(this);

            var location_id = $elem.data('location-id');

            var popupContent = quickverleih.template.render('./includes/einstellung/templates/delete_location_popup._', {});

            var popup = openPopup(popupContent, '500px');

            var confirm_delete_button = popup.find('.delete-location-yes');

            confirm_delete_button.off('click').on('click', function () {

                quickverleih.settings.api.deleteLocation(location_id, function (response) {
                    if(!response.ok) {
                        var message = response.message;
                        showAlert(message);
                        return false;
                    }

                    var $trParent = $elem.parents('tr').first();

                    var locations_table = _content;
                    var dataTable = locations_table.DataTable();

                    dataTable.row($trParent).remove().draw(false);

                    closePopup();
                });
            });
        };

        var delete_location_button = _content.find('.delete-location');
        delete_location_button.off('click').on('click', _deleteEmpFn);
    }

    return {
        init: init,
        show_add_new_location_popup: _show_add_new_location_popup
    };
})();
